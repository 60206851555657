//Projects
export const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS';
export const PROJECT_ERROR = 'PROJECT_ERROR';
export const ADD_PROJECT = 'ADD_PROJECT';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

//Team
export const ADD_TEAM_MEMBER = 'ADD_TEAM_MEMBER';
export const GET_ALL_TEAM_MEMBERS = 'GET_ALL_TEAM_MEMBERS';
export const GET_SINGLE_TEAM_MEMBER = 'GET_SINGLE_TEAM_MEMBER';
export const UPDATE_TEAM_MEMBER = 'UPDATE_TEAM_MEMBER';
export const DELETE_TEAM_MEMBER = 'DELETE_TEAM_MEMBER';
export const TEAM_ERROR = 'TEAM_ERROR';

//Resources
export const ADD_RESOURCE = 'ADD_RESOURCE';
export const DELETE_RESOURCE = 'DELETE_RESOURCE';
export const EDIT_RESOURCE = 'EDIT_RESOURCE';
export const GET_ALL_RESOURCES = 'GET_ALL_RESOURCES';
export const RESOURCE_ERROR = 'RESOURCE_ERROR';

//Services
export const GET_SERVICES = 'GET_SERVICES';
export const SERVICE_ERROR = 'SERVICE_ERROR';
export const ADD_SERVICE = 'ADD_SERVICE';
export const EDIT_SERVICE = 'EDIT_SERVICE';
export const DELETE_SERVICE = 'DELETE_SERVICE';

//Faqs
export const GET_FAQS = 'GET_FAQS';
export const FAQ_ERROR = 'FAQ_ERROR';
export const ADD_FAQ = 'ADD_FAQ';
export const EDIT_FAQ = 'EDIT_FAQ';
export const DELETE_FAQ = 'DELETE_FAQ';

//Staff members
export const GET_MEMBERS = 'GET_MEMBERS';
export const MEMBER_ERROR = 'MEMBER_ERROR';
export const ADD_MEMBER = 'ADD_MEMBER';
export const EDIT_MEMBER = 'EDIT_MEMBER';
export const DELETE_MEMBER = 'DELETE_MEMBER';

//Skills
export const GET_COMPANIES = 'GET_COMPANIES';
export const COMPANY_ERROR = 'COMPANY_ERROR';
export const CREATE_COMPANY = 'CREATE_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const GET_COMPANY = 'GET_COMPANY';


//Testimonials
export const GET_TESTIMONIALS = 'GET_TESTIMONIALS';
export const TESTIMONIAL_ERROR = 'TESTIMONIAL_ERROR';
export const ADD_TESTIMONIAL = 'ADD_TESTIMONIAL';
export const EDIT_TESTIMONIAL = 'EDIT_TESTIMONIAL';
export const PUBLISH_TESTIMONIAL = 'PUBLISH_TESTIMONIAL';
export const DELETE_TESTIMONIAL = 'DELETE_TESTIMONIAL';

//User
export const ADD_USER = 'ADD_USER';
export const EDIT_USER = 'EDIT_USER';
export const GET_USERS = 'GET_USERS';
export const DELETE_USER = 'DELETE_USER';
export const CHANGE_ROLE = 'CHANGE_ROLE';
export const USER_ERROR = 'USER_ERROR';

//User
export const GET_COMMUNITY_USERS = 'GET_COMMUNITY_USERS';
export const DELETE_COMMUNITY_USER = 'DELETE_COMMUNITY_USER';
export const CHANGE_COMMUNITY_ROLE = 'CHANGE_COMMUNITY_ROLE';
export const USER_COMMUNITY_ERROR = 'USER_COMMUNITY_ERROR';

//Authentication
export const LOADING = 'LOADING';
export const AUTHENTICATED = 'AUTHENTICATED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const UPDATE_AUTHOR = 'UPDATE_AUTHOR';

//Payments
export const GET_PAYMENTS = 'GET_PAYMENTS';
export const DECIDE_ON_PAYMENTS = 'DECIDE_ON_PAYMENTS';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const PAYMENT_LOADING = 'PAYMENT_LOADING';

//Messages
export const GET_ALL_MESSAGES = "GET_ALL_MESSAGES";
export const EDIT_MESSAGE = "EDIT_MESSAGE";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
