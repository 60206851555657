import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
// @mui
import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
  Alert,
  CircularProgress,
} from '@mui/material';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';

import { useFetcher } from 'src/api/fetcher';
import { JobService } from 'src/api';
import TypesList from './TypesList';

// ----------------------------------------------------------------------

TypesSidebar.propTypes = {
  openSidebar: PropTypes.bool,
  onOpenSidebar: PropTypes.func,
  onCloseSidebar: PropTypes.func,
};

export default function TypesSidebar({
  openSidebar,
  onOpenSidebar,
  onCloseSidebar,
}) {
  //----------------------------------------------------------------
  const [types, setTypes] = useState([]);
  const {
    data: typesData,
  } = useFetcher(`/job/getAllJobTypes`);
  console.log(types);
  useMemo(() => {
    return setTypes(typesData?.data || []);
  }, [typesData?.data]);

  //----------------------------------------------------------------
  const [editing, setEditing] = useState(null);
  //----------------------------------------------------------------
  const [type, setType] = useState('');
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setError();
    if (!type) {
      setError('Type is required');
      return;
    }
    setError();
    if (editing) {
      handleEdit(editing, type);
      return;
    }
    try {
      setLoading(true);
      const res = await JobService.addJobType({ type });
      setType('');
      setTypes(prev => [...prev, res.data.data]);
    } catch (error) {
      setError('Failed to add new type, try again later');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async id => {
    try {
      setError(null);
      const res = await JobService.deleteJobType(id);
      if (res.data.successMessage) {
        setTypes(prev => prev.filter(c => c._id !== id));
      }
    } catch (error) {
      setError('Delete failed, try again later');
    }
  };

  const handleEdit = async (id, newType) => {
    try {
      setError(null);
      setLoading(true);
      const res = await JobService.updateJobType(id, newType);
      if (res.data.successMessage) {
        setTypes(prev =>
          prev.map(type =>
            type._id === res.data.data._id
              ? res.data.data
              : type,
          ),
        );
      }
      setEditing(null);
    } catch (error) {
      setError('Edit category failed, try again later');
    } finally {
      setLoading(false);
    }
  };

  useMemo(() => {
    if (editing) {
      setType(
        types.filter(type => type._id === editing)[0]
          .type,
      );
    } else {
      setType('');
    }
  }, [editing]);

  return (
    <>
      <Button
        onClick={onOpenSidebar}
        variant="outlined"
        color="secondary"
        size="small"
      >
        Job types
      </Button>

      <Drawer
        anchor="right"
        open={openSidebar}
        onClose={onCloseSidebar}
        PaperProps={{
          sx: { width: 320, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Job Types
          </Typography>
          <IconButton onClick={onCloseSidebar}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
        {error && (
          <Alert variant="standard" severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ px: 3 }}>
            <TypesList
              types={types}
              disabled={editing}
              onDelete={typeId => {
                handleDelete(typeId);
              }}
              onEdit={typeId => {
                if (editing) return;
                setEditing(typeId);
              }}
            />
            <form onSubmit={handleSubmit}>
              <Typography variant="subtitle1" sx={{ mb: 2 }}>
                {!editing ? 'Add new' : 'Edit'} type
              </Typography>
              <TextField
                label="Type"
                color="secondary"
                value={type}
                onChange={e => setType(e.target.value)}
                fullWidth
                required
              />
              <Box sx={{ py: 2 }}>
                <Stack direction="row" spacing={2}>
                  {editing && !loading && (
                    <Button
                      color="error"
                      variant="outlined"
                      fullWidth
                      size="large"
                      onClick={() => {
                        setError('');
                        setEditing(null);
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  {!loading ? (
                    <Button
                      fullWidth
                      size="large"
                      type="submit"
                      color="inherit"
                      variant="outlined"
                    >
                      {editing ? 'Update' : 'Submit'}
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      size="large"
                      color="inherit"
                      variant="outlined"
                    >
                      <CircularProgress color="inherit" size={20} />
                      &nbsp; {editing ? 'Updating...' : 'Creating'}
                    </Button>
                  )}
                </Stack>
              </Box>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
