import { Helmet } from 'react-helmet-async';
import {
  Grid,
  Button,
  Container,
  Stack,
  Typography,
  Alert,
  Box,
} from '@mui/material';
import Iconify from '../../components/iconify';
import TrainingCard from '../../sections/training/TrainingCard';
import { Link } from 'react-router-dom';
import { useFetcher } from 'src/api/fetcher';
import { useMemo, useState } from 'react';
import DataWidget from 'src/components/widgets/DataWidget';
import { TrainingService } from 'src/api';
import CategoriesSidebar from 'src/sections/training/CategoriesSidebar';
import CandidatesSidebar from 'src/sections/training/CandidateSidebar';

export default function TrainingsPage() {
  const {
    data: trainingData,
    isError,
    isLoading,
    refetch,
  } = useFetcher(`/training/getAllTrainings?all=admin`);

  const [trainings, setTrainings] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState('');
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openCandidatesSidebar, setOpenCandidatesSidebar] =
    useState(false);
  const [allCandidates, setAllCandidates] = useState(null);

  const handleOpenSidebar = () => {
    setOpenSidebar(true);
  };

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };

  useMemo(
    () => setTrainings(trainingData?.data || []),
    [trainingData?.data],
  );

  return (
    <>
      <Helmet>
        <title>Trainings | TGHrC</title>
      </Helmet>

      <Container>
        {error && (
          <Alert variant="standard" severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert variant="standard" severity="success" sx={{ mt: 2 }}>
            {success}
          </Alert>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Trainings
          </Typography>

          <Box sx={{ flexGrow: 1 }}></Box>

          <CategoriesSidebar
            openSidebar={openSidebar}
            onOpenSidebar={handleOpenSidebar}
            onCloseSidebar={handleCloseSidebar}
          />

          <Link to="create" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Training
            </Button>
          </Link>
        </Stack>

        <DataWidget
          title={'Trainings'}
          isLoading={isLoading && !trainings.length && !isError}
          isError={
            isError && !trainings.length && !isLoading
              ? isError
              : null
          }
          isEmpty={!isLoading && !trainings.length && !isError}
        >
          <Grid container spacing={3}>
            {trainings.map((item, index) => {
              return (
                <TrainingCard
                  key={item?._id}
                  training={item}
                  index={index}
                  onDelete={async () => {
                    await TrainingService.deleteTraining(item?._id);
                    refetch();
                  }}
                  onPublish={async () => {
                    setSuccess('');
                    setError('');
                    const res = await TrainingService.publishTraining(
                      item?._id,
                      { isPublic: !item?.isPublic },
                    );
                    if (res.error) {
                      setError(res.error);
                      return;
                    }
                    setSuccess(res.message);
                    setTrainings(prev =>
                      prev.map((postA, index) =>
                        postA.id === item?._id
                          ? { ...postA, isPublic: !item?.isPublic }
                          : postA,
                      ),
                    );
                  }}
                  setAllCandidates={setAllCandidates}
                  setOpenSidebar={setOpenCandidatesSidebar}
                />
              );
            })}
          </Grid>
        </DataWidget>

        <CandidatesSidebar
          openSidebar={openCandidatesSidebar}
          onOpenSidebar={() => setOpenCandidatesSidebar(true)}
          onCloseSidebar={() => setOpenCandidatesSidebar(false)}
          candidates={allCandidates}
        />
      </Container>
    </>
  );
}
