import {
  Avatar,
  Checkbox,
  FormControl,
  IconButton,
  Link,
  MenuItem,
  Popover,
  Select,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import ModalDialog from 'src/components/Global/ModalDialog';
import Iconify from 'src/components/iconify/Iconify';

export const LEVELS = {
  user: 0,
  admin: 1,
  finance_admin: 2,
  registrar_admin: 2,
  super_admin: 3,
};

export const ROLES = [
  { label: 'User', value: 'user' },
  { label: 'Admin', value: 'admin' },
  { label: 'Super Admin', value: 'super_admin' },
];
//------------------------------------------------------------------------------

const UserListTile = ({
  user,
  selectedUser,
  onCheckBoxClicked,
  editRole,
  currentUserId,
  currentUserRole,
  deleteUser,
}) => {
  const {
    _id: id,
    names,
    role,
    email,
    isVerified,
    picture,
    accountType,
  } = user;
  //Current role state
  const [currentRole, setCurrentRole] = useState(role);
  useMemo(() => {
    if (currentRole !== role) {
      setCurrentRole(role);
    }
  }, [role]);
  //Handle change current role
  const onChangeRole = e => {
    const { value } = e.target;
    setCurrentRole(value);
    handleOpenModal();
  };

  //Open menu
  const [openMenu, setOpenMenu] = useState(null);
  const handleOpenMenu = e => {
    setOpenMenu(e.target);
  };
  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  const [openModal, setOpenModal] = useState({
    show: false,
    action: null,
  });
  const handleOpenModal = action => {
    setOpenModal({ show: true, action });
  };
  const handleCloseModal = () => {
    setOpenModal({ show: false, action: null });
    handleCloseMenu(null);
    setCurrentRole(role);
  };
  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        role="checkbox"
        selected={selectedUser}
      >
        <TableCell component="th" scope="row" padding="none" sx={{ paddingLeft: 3 }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={user?.firstName +" "+ user?.lastName} src={user?.profilePicture} />
            <Typography variant="subtitle2" noWrap>
              {user?.firstName +" "+ user?.lastName}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="left">
          <Link color="inherit" underline="hover">
            {email}
          </Link>
        </TableCell>

        <TableCell align="left">{user?.phone}</TableCell>

        <TableCell align="left">
          {user?.country}
        </TableCell>

        <TableCell align="left">
          {user?.address}
        </TableCell>

        <TableCell align="right">
          <IconButton
            size="large"
            color="inherit"
            onClick={handleOpenMenu}
          >
            <Iconify icon={'eva:more-vertical-fill'} />
          </IconButton>
        </TableCell>
      </TableRow>
      <Popover
        open={Boolean(openMenu)}
        anchorEl={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          sx={{ color: 'success.main' }}
          component='a'
          href={`${process.env.REACT_APP_CAREERS_URL}/candidate/${user?.slug}`}
          target='_blank'
        >
          <Iconify icon={'mdi:eye'} sx={{ mr: 2 }} />
          View 
        </MenuItem>
        <MenuItem
          sx={{ color: 'error.main' }}
          disabled={
            currentUserId === id ||
            LEVELS[currentUserRole] <= LEVELS[role]
          }
          onClick={() => handleOpenModal('DELETE')}
        >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Remove
        </MenuItem>
      </Popover>
      <ModalDialog
        title={
          openModal.action === 'DELETE'
            ? 'Delete Candidate?'
            : 'Confirm this action!'
        }
        subTitle={
          openModal.action === 'DELETE'
            ? `Are you sure you want to delete this candidate? He won't be able to receive our job notifications everytime a new job is published!`
            : `This action will change what a user can or can't access on TGHrC Platform!`
        }
        open={openModal.show}
        handleClose={handleCloseModal}
        handleClickOk={() => {
          if (openModal.action === 'DELETE') {
            deleteUser(id);
          } else {
            editRole(id, {
              role: currentRole,
            });
          }
          handleCloseModal();
        }}
      />
    </>
  );
};

export default UserListTile;
