import React from 'react';
// @mui
import { List } from '@mui/material';
import IndustryTile from './IndustryTile';

const IndustriesList = ({
  industries,
  onEdit,
  onDelete,
  disabled,
}) => {
  return (
    <List>
      {industries.map((item, index) => {
        const industry = {
          id: item._id,
          industry: item.industry,
        };
        const isDisabled = disabled === industry.id;

        return (
          <IndustryTile
            onEdit={id => onEdit(id)}
            onDelete={id => onDelete(id)}
            industry={industry}
            isDisabled={isDisabled}
            key={index}
          />
        );
      })}
    </List>
  );
};

export default IndustriesList;