import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import {
  Alert,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { connect } from 'react-redux';
import {
  addTeamMember,
  deleteTeamMember,
  getSingleTeamMember,
  getAllTeamMembers,
  updateTeamMember,
} from 'src/redux/actions/team';
import {
  ADD_TEAM_MEMBER,
  DELETE_TEAM_MEMBER,
  GET_SINGLE_TEAM_MEMBER,
  GET_ALL_TEAM_MEMBERS,
  UPDATE_TEAM_MEMBER,
} from 'src/redux/actionTypes';
import DataWidget from 'src/components/widgets/DataWidget';
import CreateTeamSidebar from 'src/sections/team/TeamSidebar';
import TeamCard from 'src/sections/team/TeamCard';

const TeamPage = ({
  teamMembers,
  error,
  getAvailableTeamMembers,
  paginationDetails,
  deleteTeamMember,
  addTeamMember,
  message,
  updateTeamMember,
  loading,
}) => {
  useEffect(() => {
    if (teamMembers.length === 0) {
      getAvailableTeamMembers({});
    }
  }, []);

  const [openSidebar, setOpenSidebar] = useState({
    show: false,
    data: null,
  });

  const handleOpenSidebar = data => {
    setOpenSidebar({ show: true, data });
  };

  const handleCloseSidebar = () => {
    setOpenSidebar({ show: false, data: null });
  };

  const [deleting, setDeleting] = useState();
  const [deleteError, setDeleteError] = useState();
  const [deleteSuccess, setDeleteSuccess] = useState();

  useEffect(() => {
    if (error?.action === DELETE_TEAM_MEMBER) {
      setDeleteError(error.message);
    }
    if (message?.action === DELETE_TEAM_MEMBER) {
      setDeleteSuccess(message.success);
    }
    if (
      (message?.action === UPDATE_TEAM_MEMBER ||
        message?.action === ADD_TEAM_MEMBER)
    ) {
      setDeleteSuccess(
        message.success,
      );
      handleCloseSidebar();
    }
    setDeleting();
  }, [message, error]);

  return (
    <>
      <Helmet>
        <title> Dashboard: Team | TGHrC </title>
      </Helmet>

      <Container>
        {deleteError && (
          <Alert variant="standard" severity="error" sx={{ mt: 2 }}>
            {deleteError}
          </Alert>
        )}
        {deleteSuccess && (
          <Alert variant="standard" severity="success" sx={{ mt: 2 }}>
            {deleteSuccess}
          </Alert>
        )}
        {deleting && (
          <Alert
            variant="standard"
            severity="info"
            icon={<CircularProgress size={20} />}
            sx={{ mt: 2 }}
          >
            Deleting <strong>"{deleting.title}"</strong> team member ...
          </Alert>
        )}
        <Stack
          direction="row"
          spacing={1}
          flexShrink={0}
          sx={{ my: 1, mb: 5 }}
          justifyContent="space-between"
        >
          <Typography variant="h4">Team</Typography>
          <CreateTeamSidebar
            openSidebar={openSidebar.show}
            onOpenSidebar={() => handleOpenSidebar(null)}
            data={openSidebar.data}
            onCloseSidebar={() => handleCloseSidebar()}
            errorApi={error}
            message={message}
            onEditSubmitted={edited => {
              updateTeamMember(openSidebar.data._id, edited);
            }}
            onSubmit={data => {
              addTeamMember(data);
            }}
          />
        </Stack>
        <DataWidget
          title={'Team'}
          isLoading={loading && !teamMembers.length && !error}
          isError={
            !loading && error && !teamMembers.length ? error : null
          }
          isEmpty={!error && !loading && !teamMembers.length}
        >
          <Grid container spacing={3}>
            {teamMembers.map(member => (
              <Grid key={member._id} item xs={12} sm={6} md={4}>
                <TeamCard
                  member={member}
                  onEdit={() => {
                    handleOpenSidebar(member);
                  }}
                  isDeleting={
                    deleting?.id === member._id ||
                    openSidebar.data?._id === member._id
                  }
                  onDelete={() => {
                    setDeleting({
                      id: member._id,
                      title: member.title,
                    });
                    setDeleteSuccess();
                    setDeleteError();
                    return deleteTeamMember(member._id);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </DataWidget>
      </Container>
    </>
  );
};

const mapStateToProps = state => ({
  teamMembers: state.team.members,
  message: state.team.message,
  paginationDetails: state.team.paginationDetails,
  error: state.team.error,
  loading: state.team.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    getAvailableTeamMembers: queries =>
      dispatch(getAllTeamMembers(queries)),
    addTeamMember: data => dispatch(addTeamMember(data)),
    deleteTeamMember: id => dispatch(deleteTeamMember(id)),
    updateTeamMember: (id, data) => dispatch(updateTeamMember(id, data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamPage);