const navConfig = user => {
  const config = [
    {
      title: 'dashboard',
      path: '/dashboard/app',
      icon: 'material-symbols:dashboard-outline',
      itemRole: 'all',
    },
    {
      title: 'users',
      path: '/dashboard/users',
      icon: 'ci:users',
      itemRole: 'all',
    },
    {
      title: 'careers',
      path: '/dashboard/jobs',
      icon: 'oui:ml-regression-job',
      itemRole: 'all',
    },
    {
      title: 'trainings',
      path: '/dashboard/trainings',
      icon: 'oui:training',
      itemRole: 'all',
    },
    {
      title: 'talent community',
      path: '/dashboard/talent-community',
      icon: 'fa6-solid:people-line',
      itemRole: 'all',
    },
    {
      title: 'projects',
      path: '/dashboard/projects',
      icon: 'octicon:project-24',
      itemRole: 'all',
    },
    {
      title: 'Faqs',
      path: '/dashboard/faq',
      icon: 'pajamas:question-o',
      itemRole: 'all',
    },
    {
      title: 'about',
      path: '/dashboard/about',
      icon: 'lucide:info',
      itemRole: 'all',
    },
    {
      title: 'services',
      path: '/dashboard/services',
      icon: 'carbon:service-id',
      itemRole: 'all',
    },
    {
      title: 'blogs',
      path: '/dashboard/blogs',
      icon: 'jam:blogger-square',
      itemRole: 'all',
    },
    {
      title: 'gallery',
      path: '/dashboard/gallery',
      icon: 'solar:gallery-bold',
      itemRole: 'all',
    },
    {
      title: 'staff members',
      path: '/dashboard/team',
      icon: 'ri:team-line',
      itemRole: 'all',
    },
    {
      title: 'testimonials',
      path: '/dashboard/testimonials',
      icon: 'teenyicons:message-tick-outline',
      itemRole: 'all',
    },
    {
      title: 'resources',
      path: '/dashboard/resources',
      icon: 'grommet-icons:resources',
      itemRole: 'all',
    },
    {
      title: 'messages',
      path: '/dashboard/messages',
      icon: 'ic:outline-message',
      itemRole: 'all',
    },
  ];

  if (user?.allowedFeatures?.includes('All')) {
    return config;
  } else {
    return config.filter(
      item =>
        item.title.toLowerCase() === 'dashboard' ||
        user?.allowedFeatures
          ?.map(feature => feature.toLowerCase())
          .includes(item.title.toLowerCase()),
    );
  }
};

export default navConfig;
