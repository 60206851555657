import { AxiosError } from 'axios';
import { FaqService } from 'src/api';
import * as actions from '../actionTypes';

export const getAllFaqs = () => {
  return async dispatch => {
    try {
      const result = await FaqService.getFaqs();
      if (!result.data.allFaqs) {
        throw new Error('Failed to retrieve faqs');
      }
      dispatch({
        type: actions.GET_FAQS,
        payload: {
          faqs: result.data.allFaqs,
        },
      });
    } catch (error) {
      dispatch({
        type: actions.FAQ_ERROR,
        payload: {
          error: {
            action: actions.GET_FAQS,
            message:
              error.message === 'Network Error'
                ? 'Server unreachable, please check your internet connection and try again'
                : 'Something went wrong while retrieving faqs, try again later',
            at: new Date(),
          },
        },
      });
    }
  };
};

export const addFaq = data => async dispatch => {
  try {
    const response = await FaqService.createFaq(data);
    dispatch({
      type: actions.ADD_FAQ,
      payload: {
        message: {
          action: actions.ADD_FAQ,
          success: response.data.successMessage,
        },
        faq: response.data.faqContent,
      },
    });
  } catch (error) {
    if (error instanceof AxiosError) {
      dispatch({
        type: actions.FAQ_ERROR,
        payload: {
          error: {
            action: actions.ADD_FAQ,
            message:
              error.response.data?.message ||
              error.response.data?.validationError ||
              error.message ||
              'Unknown error has occured',
            at: new Date(),
          },
        },
      });
      return;
    }
    dispatch({
      type: actions.FAQ_ERROR,
      payload: {
        error: {
          action: actions.ADD_FAQ,
          message: error.message,
          at: new Date(),
        },
      },
    });
  }
};

export const editFaq = (id, body) => async dispatch => {
  try {
    const response = await FaqService.editFaq(id, body);
    dispatch({
      type: actions.EDIT_FAQ,
      payload: {
        message: {
          action: actions.EDIT_FAQ,
          success: response.data.successMessage,
        },
        faq: response.data.faqContent,
      },
    });
  } catch (error) {
    if (error instanceof AxiosError) {
      dispatch({
        type: actions.FAQ_ERROR,
        payload: {
          error: {
            action: actions.EDIT_FAQ,
            message:
              error.response.data?.message ||
              error.response.data?.validationError ||
              error.message ||
              'Unknown error has occured',
            at: new Date(),
          },
        },
      });
      return;
    }
    dispatch({
      type: actions.FAQ_ERROR,
      payload: {
        error: {
          action: actions.EDIT_FAQ,
          message: error.message,
          at: new Date(),
        },
      },
    });
  }
};

export const deleteFaq = id => async dispatch => {
  try {
    const response = await FaqService.deleteFaq(id);
    dispatch({
      type: actions.DELETE_FAQ,
      payload: {
        message: {
          action: actions.DELETE_FAQ,
          success: response.data.successMessage,
        },
        id,
      },
    });
  } catch (error) {
    if (error instanceof AxiosError) {
      dispatch({
        type: actions.FAQ_ERROR,
        payload: {
          error: {
            action: actions.DELETE_FAQ,
            message:
              error.response.data?.message ||
              error.message ||
              'Unknown error has occured while deleting Faq',
            at: new Date(),
          },
        },
      });
      return;
    }
    dispatch({
      type: actions.FAQ_ERROR,
      payload: {
        error: {
          action: actions.DELETE_FAQ,
          message: error.message,
          at: new Date(),
        },
      },
    });
  }
};
