import PropTypes from 'prop-types';
import { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import ChooseFileImage from 'src/components/Global/files/ChooseFileImage';
import {
  CREATE_COMPANY,
  UPDATE_COMPANY,
} from 'src/redux/actionTypes';
import RichTextEditor from 'src/components/blog/RichTextEditor';
import { useFetcher } from 'src/api/fetcher';

CreateCompanySidebar.propTypes = {
  openSidebar: PropTypes.bool,
  onOpenSidebar: PropTypes.func,
  onCloseSidebar: PropTypes.func,
};

export default function CreateCompanySidebar({
  openSidebar,
  onOpenSidebar,
  onCloseSidebar,
  onSubmit,
  data,
  errorApi,
  message,
}) {
  const [companyName, setCompanyName] = useState('');
  const [aboutTheCompany, setAboutTheCompany] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [twitter, setTwitter] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [nberOfEmployees, setNberOfEmployees] = useState('');
  const [category, setCategory] = useState('');
  const [location, setLocation] = useState('');
  const [registrationDate, setRegistrationDate] = useState('');
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { data: categoriesData } = useFetcher(
    `/job/company/getAllCategories`,
  );

  const categories = useMemo(() => {
    return categoriesData?.data || [];
  }, [categoriesData?.data]).map(item => {
    return {
      id: item._id,
      name: item.name,
    };
  });

  const handleSubmit = e => {
    e.preventDefault();
    setError();
    if (
      !companyName ||
      !aboutTheCompany ||
      !phoneNumber ||
      !nberOfEmployees ||
      !category ||
      !location
    ) {
      setError('Fields marked with a * are required');
      return;
    }

    setError();
    setLoading(true);
    const companyData = {
      companyName,
      aboutTheCompany,
      companyLogo,
      companyWebsite,
      companyEmail,
      linkedin,
      twitter,
      phoneNumber,
      nberOfEmployees,
      category_id: category,
      location,
      registrationDate,
    };
    if (!data) {
      onSubmit({
        create: true,
        data: companyData,
      });
    } else {
      onSubmit({
        create: false,
        data: companyData,
      });
    }
  };

  useEffect(() => {
    setLoading(false);
    if (message?.action === CREATE_COMPANY && !data) {
      setCompanyName('');
      setAboutTheCompany('');
      setCompanyLogo('');
      setCompanyWebsite('');
      setCompanyEmail('');
      setLinkedin('');
      setTwitter('');
      setPhoneNumber('');
      setNberOfEmployees('');
      setCategory('');
      setLocation('');
      setRegistrationDate('');
    }
    if (message?.action === UPDATE_COMPANY) {
      setCompanyName('');
      setAboutTheCompany('');
      setCompanyLogo('');
      setCompanyWebsite('');
      setCompanyEmail('');
      setLinkedin('');
      setTwitter('');
      setPhoneNumber('');
      setNberOfEmployees('');
      setCategory('');
      setLocation('');
      setRegistrationDate('');
    }
    if (data) {
      setCompanyName(data.companyName);
      setAboutTheCompany(data.aboutTheCompany);
      setCompanyLogo(data.companyLogo);
      setCompanyWebsite(data.companyWebsite);
      setCompanyEmail(data.companyEmail);
      setLinkedin(data.linkedin);
      setTwitter(data.twitter);
      setPhoneNumber(data.phoneNumber);
      setNberOfEmployees(data.nberOfEmployees);
      setCategory(data.category_id);
      setLocation(data.location);
      setRegistrationDate(data.registrationDate);
    } else {
      setCompanyName('');
      setAboutTheCompany('');
      setCompanyLogo('');
      setCompanyWebsite('');
      setCompanyEmail('');
      setLinkedin('');
      setTwitter('');
      setPhoneNumber('');
      setNberOfEmployees('');
      setCategory('');
      setLocation('');
      setRegistrationDate('');
    }
  }, [data, message]);

  useEffect(() => {
    setError(
      errorApi?.action === CREATE_COMPANY ||
        errorApi?.action === UPDATE_COMPANY
        ? errorApi?.message
        : '',
    );
    setLoading(false);
  }, [errorApi]);

  // Add the JSX for the new fields here
  // ...
  return (
    <>
      <Button
        onClick={onOpenSidebar}
        variant="contained"
        color="secondary"
        startIcon={<AddCircleOutlineOutlined />}
      >
        New Company
      </Button>

      <Drawer
        anchor="right"
        open={openSidebar}
        onClose={onCloseSidebar}
        PaperProps={{
          sx: { width: 520, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            {data ? 'Update' : 'Add'} Company
          </Typography>
          <IconButton onClick={onCloseSidebar}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
        {error && (
          <Alert variant="standard" severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <TextField
              label="Company Name"
              color="secondary"
              fullWidth
              required
              value={companyName}
              onChange={e => setCompanyName(e.target.value)}
            />
            <ChooseFileImage
              selected={companyLogo}
              title="Company Logo *"
              onSelect={selected => setCompanyLogo(selected)}
            />
            <FormControl fullWidth sx={{ my: 1 }}>
              <InputLabel id="demo-simple-select-label">
                Company Category *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                onChange={e => setCategory(e.target.value)}
                label="Select Category"
                required
              >
                {categories.map((item, index) => {
                  return (
                    <MenuItem value={item.id} key={index}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              label="Number of Employees"
              color="secondary"
              type='number'
              fullWidth
              required
              value={nberOfEmployees}
              onChange={e => setNberOfEmployees(e.target.value)}
            />
            <Typography variant="body2" sx={{ mb: '-30px' }}>
              Company Description *
            </Typography>
            <RichTextEditor
              name="aboutTheCompany"
              onChange={html => {
                setAboutTheCompany(html);
              }}
              placeholder="About the Company..."
              html={aboutTheCompany}
            />
            <TextField
              label="Company Phone Number"
              color="secondary"
              fullWidth
              required
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
            />
            <TextField
              label="Company Location"
              color="secondary"
              fullWidth
              required
              value={location}
              onChange={e => setLocation(e.target.value)}
            />
            <TextField
              label="Company Registration Date"
              color="secondary"
              fullWidth
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={registrationDate}
              onChange={e => setRegistrationDate(e.target.value)}
            />
            <TextField
              label="Company Website"
              color="secondary"
              fullWidth
              value={companyWebsite}
              onChange={e => setCompanyWebsite(e.target.value)}
            />
            <TextField
              label="Company Email"
              color="secondary"
              fullWidth
              value={companyEmail}
              onChange={e => setCompanyEmail(e.target.value)}
            />
            <TextField
              label="Linkedin"
              color="secondary"
              fullWidth
              value={linkedin}
              onChange={e => setLinkedin(e.target.value)}
            />
            <TextField
              label="Twitter"
              color="secondary"
              fullWidth
              value={twitter}
              onChange={e => setTwitter(e.target.value)}
            />
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          {!loading ? (
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              onClick={handleSubmit}
              variant="outlined"
            >
              {data ? 'Update' : 'Submit'}
            </Button>
          ) : (
            <Button
              fullWidth
              size="large"
              color="inherit"
              variant="outlined"
            >
              <CircularProgress color="inherit" size={20} />
              &nbsp; {data ? 'Updating' : 'Creating'}
            </Button>
          )}
        </Box>
      </Drawer>
    </>
  );
}
