import React, { useState } from 'react';
import { Delete, Edit } from '@mui/icons-material';
import {
  Stack,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
} from '@mui/material';
import ModalDialog from 'src/components/Global/ModalDialog';

const IndustryTile = ({ onEdit, onDelete, isDisabled, industry }) => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <ListItem
        secondaryAction={
          <Stack direction="row">
            <IconButton
              edge="end"
              aria-label="edit"
              size="small"
              disabled={isDisabled}
              onClick={() => onEdit(industry.id)}
            >
              <Edit size="small" />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              size="small"
              disabled={isDisabled}
              onClick={handleOpenModal}
            >
              <Delete size="small" />
            </IconButton>
          </Stack>
        }
      >
        <ListItemText
          primary={industry.industry}
          sx={{ color: isDisabled ? '#d3d3d3' : undefined }}
        />
        <ModalDialog
          title="Delete Industry?"
          subTitle={`Are you sure do you want to delete this industry? `}
          hardWarning={`This will affect all associated jobs to be not visible on the website.`}
          item={industry.industry}
          open={openModal}
          handleClose={handleCloseModal}
          handleClickOk={() => {
            handleCloseModal();
            onDelete(industry.id);
          }}
        />
      </ListItem>

      <Divider />
    </>
  );
};

export default IndustryTile;