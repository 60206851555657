import { Helmet } from 'react-helmet-async';

import {
  Grid,
  Button,
  Container,
  Stack,
  Typography,
  Alert,
  Box,
} from '@mui/material';

import Iconify from '../../components/iconify';
import { JobPostCard } from '../../sections/job';
import { Link } from 'react-router-dom';
import { useFetcher } from 'src/api/fetcher';
import { useMemo, useState } from 'react';
import DataWidget from 'src/components/widgets/DataWidget';
import CategoriesSidebar from 'src/sections/job/CategoriesSidebar';
import { JobService } from 'src/api';
import TypesSidebar from 'src/sections/job/TypesSidebar';
import IndustriesSidebar from 'src/sections/job/IndustriesSidebar';
import EmploymentStatusesSidebar from 'src/sections/job/EmploymentStatusesSidebar';

export default function JobsPage() {
  const {
    data: jobData,
    isError,
    isLoading,
    refetch,
  } = useFetcher(`/job/getAllJobs?all=admin`);

  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState('');

  useMemo(() => setJobs(jobData?.data || []), [jobData?.data]);

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleOpenSidebar = () => {
    setOpenSidebar(true);
  };

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };

  const [openJobSidebar, setOpenJobSidebar] = useState(false);

  const handleOpenJobSidebar = () => {
    setOpenJobSidebar(true);
  };

  const handleCloseJobSidebar = () => {
    setOpenJobSidebar(false);
  };

  const [openIndustrySidebar, setOpenIndustrySidebar] =
    useState(false);

  const handleOpenIndustrySidebar = () => {
    setOpenIndustrySidebar(true);
  };

  const handleCloseIndustrySidebar = () => {
    setOpenIndustrySidebar(false);
  };

  const [
    openEmploymentStatusSidebar,
    setOpenEmploymentStatusSidebar,
  ] = useState(false);

  const handleOpenEmploymentStatusSidebar = () => {
    setOpenEmploymentStatusSidebar(true);
  };

  const handleCloseEmploymentStatusSidebar = () => {
    setOpenEmploymentStatusSidebar(false);
  };

  return (
    <>
      <Helmet>
        <title>Jobs | TGHrC</title>
      </Helmet>

      <Container>
        {error && (
          <Alert variant="standard" severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert variant="standard" severity="success" sx={{ mt: 2 }}>
            {success}
          </Alert>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Jobs
          </Typography>

          <Box sx={{ flexGrow: 1 }}></Box>

          <Link to="companies" style={{ textDecoration: 'none' }}>
            <Button variant="outlined" color="secondary" size="small">
              Companies
            </Button>
          </Link>

          <TypesSidebar
            openSidebar={openJobSidebar}
            onOpenSidebar={handleOpenJobSidebar}
            onCloseSidebar={handleCloseJobSidebar}
          />

          <CategoriesSidebar
            openSidebar={openSidebar}
            onOpenSidebar={handleOpenSidebar}
            onCloseSidebar={handleCloseSidebar}
          />

          <IndustriesSidebar
            openSidebar={openIndustrySidebar}
            onOpenSidebar={handleOpenIndustrySidebar}
            onCloseSidebar={handleCloseIndustrySidebar}
          />

          <EmploymentStatusesSidebar
            openSidebar={openEmploymentStatusSidebar}
            onOpenSidebar={handleOpenEmploymentStatusSidebar}
            onCloseSidebar={handleCloseEmploymentStatusSidebar}
          />

          <Link to="create" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Job
            </Button>
          </Link>
        </Stack>

        <DataWidget
          title={'Jobs'}
          isLoading={isLoading && !jobs.length && !isError}
          isError={
            isError && !jobs.length && !isLoading ? isError : null
          }
          isEmpty={!isLoading && !jobs.length && !isError}
        >
          <Grid container spacing={3}>
            {jobs.map((item, index) => {
              return (
                <JobPostCard
                  key={item?._id}
                  job={item}
                  index={index}
                  onDelete={async () => {
                    await JobService.deleteJob(item?._id);
                    refetch();
                  }}
                  onPublish={async () => {
                    setSuccess('');
                    setError('');
                    const res = await JobService.publishJob(
                      item?._id,
                      { isPublic: !item?.isPublic },
                    );
                    if (res.error) {
                      setError(res.error);
                      return;
                    }
                    setSuccess(res.message);
                    setJobs(prev =>
                      prev.map((postA, index) =>
                        postA.id === item?._id
                          ? { ...postA, isPublic: !item?.isPublic }
                          : postA,
                      ),
                    );
                  }}
                />
              );
            })}
          </Grid>
        </DataWidget>
      </Container>
    </>
  );
}
