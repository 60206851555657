import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Box,
  Checkbox,
  Stack,
} from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import RichTextEditor from 'src/components/blog/RichTextEditor';
import { useMemo, useState, useRef } from 'react';
import { useFetcher } from 'src/api/fetcher';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ChooseFileField from 'src/components/Global/ChooseFileField';
import { format } from 'date-fns';
import dayjs from 'dayjs';

const CreateTrainingForm = ({
  onCreate,
  loading,
  data: editData,
}) => {
  const [name, setName] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [trainingDescription, setTrainingDescription] = useState('');
  const [trainingDocument, setTrainingDocument] = useState();
  const [isPublic, setIsPublic] = useState(true);

  useMemo(() => {
    if (editData) {
      setName(editData.name);
      setCategoryId(editData.category_id);
      setStartDate(dayjs(editData.startDate));
      setEndDate(dayjs(editData.endDate));
      setTrainingDescription(editData.trainingDescription);
      setTrainingDocument(editData.trainingDocument);
      setIsPublic(editData.isPublic || true);
    }
  }, [editData]);

  //Errors
  const [formError, setFormError] = useState(null);

  const { data: categoriesData } = useFetcher(
    `/training/getAllCategories`,
  );

  const categories = useMemo(() => {
    return categoriesData?.data || [];
  }, [categoriesData?.data]).map(item => {
    return {
      id: item._id,
      name: item.name,
    };
  });

  const handleSubmit = e => {
    e.preventDefault();
    const validation = {
      name: !name ? 'Name required' : false,
      categoryId: !categoryId ? 'Category ID required' : false,
      startDate: !startDate ? 'Start date required' : false,
      trainingDescription: !trainingDescription
        ? 'Training description required'
        : false,
      trainingDocument: !trainingDocument
        ? 'Training document required'
        : false,
    };

    if (!Object.values(validation).every(val => !val)) {
      setFormError(validation);
      return;
    }

    let trainingData = {
      name,
      category_id: categoryId,
      startDate,
      endDate,
      trainingDescription,
      trainingDocument,
      isPublic,
    };

    if (editData) {
      onCreate(trainingData);
      return;
    }
    onCreate(trainingData);
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        label="Training Name"
        color="secondary"
        fullWidth
        sx={{ my: 1 }}
        value={name}
        onChange={e => setName(e.target.value)}
        error={Boolean(formError?.name)}
      />
      <FormControl fullWidth sx={{ my: 1 }}>
        <InputLabel id="demo-simple-select-label">
          Training Category
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={categoryId}
          onChange={e => setCategoryId(e.target.value)}
          label="Select Category"
          error={Boolean(formError?.category)}
        >
          {categories.map((item, index) => {
            return (
              <MenuItem value={item.id} key={index}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        gap="20px"
        my={1}
      >
        <DatePicker
          label="Training Start Date"
          value={startDate}
          onChange={newValue => {
            setStartDate(newValue);
          }}
          sx={{ width: '100%' }}
          renderInput={params => (
            <TextField
              {...params}
              value={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
              fullWidth
              color="secondary"
              error={Boolean(formError?.startDate)}
            />
          )}
        />
        <Typography variant="h4" color="initial">
          -
        </Typography>
        <DatePicker
          label="Training End Date"
          value={endDate}
          onChange={newValue => {
            setEndDate(newValue);
          }}
          sx={{ width: '100%' }}
          renderInput={params => (
            <TextField
              {...params}
              value={endDate ? format(endDate, 'yyyy-MM-dd') : ''}
              fullWidth
              color="secondary"
            />
          )}
        />
      </Stack>
      <Typography variant="body2" sx={{ mb: 1, mt: 2 }}>
        Training Description
      </Typography>
      <RichTextEditor
        onChange={html => setTrainingDescription(html)}
        placeholder="Start typing training details..."
        html={trainingDescription}
      />
      {formError?.trainingDescription && (
        <Typography variant="caption" color="error">
          {formError.trainingDescription}
        </Typography>
      )}
      <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
        Training Document
      </Typography>
      <ChooseFileField
        label="Uploaded training Document"
        acceptFileTypes="application/pdf"
        isMultiple={true}
        onChange={file => {
          setTrainingDocument(file);
        }}
        currentItem={trainingDocument}
        currentItemUrl={trainingDocument}
        chipLabel="Training Document"
        isSpecialCase={true}
      />
      <FormControl sx={{ my: 2 }}>
        <FormLabel>Visibility Options</FormLabel>
        <RadioGroup
          value={isPublic}
          onChange={e => setIsPublic(e.target.value)}
        >
          <FormControlLabel
            value={true}
            control={<Radio />}
            label="Publish immediately"
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label="Save privately"
          />
        </RadioGroup>
      </FormControl>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          size="large"
          endIcon={
            loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <KeyboardArrowRight />
            )
          }
        >
          {editData ? 'Edit' : 'Create'} Training
        </Button>
      </Box>
    </form>
  );
};

export default CreateTrainingForm;
