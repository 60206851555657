import {
    ADD_RESOURCE,
    DELETE_RESOURCE,
    EDIT_RESOURCE,
    GET_ALL_RESOURCES,
    RESOURCE_ERROR,
  } from '../actionTypes';
  
  const initResourceState = {
    error: null,
    resources: [],
    message: null,
    loading: true,
    paginationDetails: null,
  };
  
  const resourceReducer = (state = initResourceState, action) => {
    switch (action.type) {
      case GET_ALL_RESOURCES:
        return {
          ...state,
          error: null,
          loading: false,
          resources: [...action.payload.resources],
          paginationDetails: action.payload.paginationDetails,
        };
      case ADD_RESOURCE:
        return {
          ...state,
          error: null,
          message: action.payload.message,
          resources: [action.payload.resource, ...state.resources],
        };
      case EDIT_RESOURCE:
        return {
          ...state,
          error: null,
          message: action.payload.message,
          resources: state.resources.map(resource =>
            resource._id !== action.payload.resource._id
              ? resource
              : action.payload.resource,
          ),
        };
      case DELETE_RESOURCE:
        return {
          ...state,
          error: null,
          message: action.payload.message,
          resources: state.resources.filter(
            resource => resource._id !== action.payload.id,
          ),
        };
      case RESOURCE_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      default:
        return state;
    }
  };
  
  export default resourceReducer;