import API from './_api_';

// Job endpoints
const createJob = data => API.post('/job/createJob', data);
const getAllJobs = () => API.get('/job/getAllJobs');
const getSingleJob = slug =>
  API.get('/job/getSingleJob?slug=' + slug);
const updateJob = (slug, data) =>
  API.put('/job/updateJob?slug=' + slug, data);
const deleteJob = id => API.delete('/job/deleteJob/' + id);
const publishJob = (id, data) =>
  API.post('/job/publishJob/' + id, data);

// Category endpoints
const addCategory = data => API.post('/job/addCategory', data);
const getAllCategories = () => API.get('/job/getAllCategories');
const deleteCategory = id => API.delete('/job/deleteCategory/' + id);
const updateCategory = (id, name) =>
  API.put('/job/updateCategory/' + id, { name });

// Company Category endpoints
const addCompanyCategory = data =>
  API.post('/job/company/addCategory', data);
const getAllCompanyCategories = () =>
  API.get('/job/company/getAllCategories');
const deleteCompanyCategory = id =>
  API.delete('/job/company/deleteCategory/' + id);
const updateCompanyCategory = (id, name) =>
  API.put('/job/company/updateCategory/' + id, { name });

// Candidate endpoints
const createCandidate = data =>
  API.post('/job/createCandidate', data);
const getAllCandidates = () => API.get('/job/getAllCandidates');
const getSingleCandidate = id =>
  API.get('/job/getSingleCandidate?id=' + id);
const updateCandidate = (id, data) =>
  API.put('/job/updateCandidate?id=' + id, data);
const deleteCandidate = id =>
  API.delete('/job/deleteCandidate/' + id);

// Company endpoints
const createCompany = data => API.post('/job/createCompany', data);
const getAllCompanies = () => API.get('/job/getAllCompanies');
const getSingleCompany = id =>
  API.get('/job/getSingleCompany?id=' + id);
const updateCompany = (id, data) =>
  API.put('/job/updateCompany?id=' + id, data);
const deleteCompany = id => API.delete('/job/deleteCompany/' + id);

// JobType endpoints
const addJobType = data => API.post('/job/addJobType', data);
const getAllJobTypes = () => API.get('/job/getAllJobTypes');
const updateJobType = (id, type) =>
  API.put('/job/updateJobType/' + id, { type });
const deleteJobType = id => API.delete('/job/deleteJobType/' + id);

// Job Industry endpoints
const addJobIndustry = data => API.post('/job/addJobIndustry', data);
const getAllJobIndustries = () => API.get('/job/getAllJobIndustries');
const updateJobIndustry = (id, industry) =>
  API.put('/job/updateJobIndustry/' + id, industry);
const deleteJobIndustry = id =>
  API.delete('/job/deleteJobIndustry/' + id);

// Job Employment Status endpoints
const addJobEmploymentStatus = data =>
  API.post('/job/addJobEmploymentStatus', data);
const getAllJobEmploymentStatuses = () =>
  API.get('/job/getAllJobEmploymentStatuses');
const updateJobEmploymentStatus = (id, status) =>
  API.put('/job/updateJobEmploymentStatus/' + id, status);
const deleteJobEmploymentStatus = id =>
  API.delete('/job/deleteJobEmploymentStatus/' + id);

export default {
  createJob,
  getAllJobs,
  getSingleJob,
  updateJob,
  deleteJob,
  publishJob,
  addCategory,
  getAllCategories,
  deleteCategory,
  updateCategory,
  createCandidate,
  getAllCandidates,
  getSingleCandidate,
  updateCandidate,
  deleteCandidate,
  createCompany,
  getAllCompanies,
  getSingleCompany,
  updateCompany,
  deleteCompany,
  addJobType,
  getAllJobTypes,
  updateJobType,
  deleteJobType,
  addCompanyCategory,
  getAllCompanyCategories,
  deleteCompanyCategory,
  updateCompanyCategory,
  addJobIndustry,
  getAllJobIndustries,
  updateJobIndustry,
  deleteJobIndustry,
  addJobEmploymentStatus,
  getAllJobEmploymentStatuses,
  updateJobEmploymentStatus,
  deleteJobEmploymentStatus,
};
