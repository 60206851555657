import API from "./_api_";

// ADD TEAM MEMBER
const addTeamMember = (data) => API.post("/team/addTeamMember", data);

// GET ALL TEAM MEMBERS
const getAllTeamMembers = () => API.get("/team/getAllTeamMembers");

// GET SINGLE TEAM MEMBER
const getSingleTeamMember = (id) => API.get("/team/getSingleTeamMember?teamMemberId=" + id);

// UPDATE TEAM MEMBER
const updateTeamMember = (id, data) => API.patch("/team/updateTeamMember?teamMemberId=" + id, data);

// DELETE TEAM MEMBER
const deleteTeamMember = (id) => API.delete("/team/deleteTeamMember?teamMemberId=" + id);

export default { addTeamMember, getAllTeamMembers, getSingleTeamMember, updateTeamMember, deleteTeamMember };