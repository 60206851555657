import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Typography,
  TextField,
  Alert,
  CircularProgress,
  Divider,
  Stack,
} from '@mui/material';
import Iconify from '../../components/iconify';
import { useEffect, useState } from 'react';
import {
  ADD_TEAM_MEMBER,
  UPDATE_TEAM_MEMBER,
} from 'src/redux/actionTypes';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import ChooseFileImage from 'src/components/Global/files/ChooseFileImage';

CreateTeamSidebar.propTypes = {
  openSidebar: PropTypes.bool,
  onOpenSidebar: PropTypes.func,
  onCloseSidebar: PropTypes.func,
};

export default function CreateTeamSidebar({
  openSidebar,
  onOpenSidebar,
  onCloseSidebar,
  onSubmit,
  errorApi,
  message,
  data,
  onEditSubmitted,
}) {
  const [teamMemberName, setTeamMemberName] = useState('');
  const [teamMemberRole, setTeamMemberRole] = useState('');
  const [teamMemberImage, setTeamMemberImage] = useState('');
  const [teamMemberBio, setTeamMemberBio] = useState('');
  const [teamMemberPhone, setTeamMemberPhone] = useState('');
  const [teamMemberEmail, setTeamMemberEmail] = useState('');
  const [teamMemberLinkedin, setTeamMemberLinkedin] = useState('');
  const [teamMemberTwitter, setTeamMemberTwitter] = useState('');
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setError();
    if (
      !teamMemberName ||
      !teamMemberRole ||
      !teamMemberImage ||
      !teamMemberBio ||
      !teamMemberPhone ||
      !teamMemberEmail
    ) {
      setError(
        'Name, role, image, bio, phone and email are required',
      );
      return;
    }
    setError();
    setLoading(true);
    if (!data) {
      onSubmit({
        name: teamMemberName,
        role: teamMemberRole,
        image: teamMemberImage,
        bio: teamMemberBio,
        phone: teamMemberPhone,
        email: teamMemberEmail,
        linkedin: teamMemberLinkedin,
        twitter: teamMemberTwitter,
      });
    } else {
      const editedData = {
        name: teamMemberName,
        role: teamMemberRole,
        image: teamMemberImage,
        bio: teamMemberBio,
        phone: teamMemberPhone,
        email: teamMemberEmail,
        linkedin: teamMemberLinkedin,
        twitter: teamMemberTwitter,
      };
      onEditSubmitted(editedData);
    }
  };

  useEffect(() => {
    setLoading(false);
    if (message?.action === ADD_TEAM_MEMBER && !data) {
      setTeamMemberName('');
      setTeamMemberRole('');
      setTeamMemberImage('');
      setTeamMemberBio('');
      setTeamMemberPhone('');
      setTeamMemberEmail('');
      setTeamMemberLinkedin('');
      setTeamMemberTwitter('');
    }
    if (message?.action === UPDATE_TEAM_MEMBER) {
      setTeamMemberName('');
      setTeamMemberRole('');
      setTeamMemberImage('');
      setTeamMemberBio('');
      setTeamMemberPhone('');
      setTeamMemberEmail('');
      setTeamMemberLinkedin('');
      setTeamMemberTwitter('');
    }
    if (data) {
      setTeamMemberName(data.name);
      setTeamMemberRole(data.role);
      setTeamMemberImage(data.image);
      setTeamMemberBio(data.bio);
      setTeamMemberPhone(data.phone);
      setTeamMemberEmail(data.email);
      setTeamMemberLinkedin(data.linkedin);
      setTeamMemberTwitter(data.twitter);
    } else {
      setTeamMemberName('');
      setTeamMemberRole('');
      setTeamMemberImage('');
      setTeamMemberBio('');
      setTeamMemberPhone('');
      setTeamMemberEmail('');
      setTeamMemberLinkedin('');
      setTeamMemberTwitter('');
    }
  }, [message, data]);

  useEffect(() => {
    setError(
      errorApi?.action === ADD_TEAM_MEMBER ||
        errorApi?.action === UPDATE_TEAM_MEMBER
        ? errorApi?.message
        : '',
    );
    setLoading(false);
  }, [errorApi]);

  return (
    <>
      <Button
        variant="contained"
        startIcon={<Iconify icon="eva:plus-fill" />}
        onClick={() => onOpenSidebar()}
      >
        New Team Member
      </Button>

      <Drawer
        anchor="right"
        open={openSidebar}
        onClose={onCloseSidebar}
        PaperProps={{
          sx: { width: 350, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            {data ? 'Edit' : 'Create'} Team Member
          </Typography>
          <IconButton onClick={onCloseSidebar}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        {error && (
          <Alert variant="standard" severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        <Divider />
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <TextField
              label="Name"
              value={teamMemberName}
              onChange={e => setTeamMemberName(e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="Role"
              value={teamMemberRole}
              onChange={e => setTeamMemberRole(e.target.value)}
              fullWidth
              required
            />
            <ChooseFileImage
              title="Image"
              onSelect={setTeamMemberImage}
              selected={teamMemberImage}
              error={error}
            />
            <TextField
              label="Phone"
              value={teamMemberPhone}
              onChange={e => setTeamMemberPhone(e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="Email"
              value={teamMemberEmail}
              onChange={e => setTeamMemberEmail(e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="Bio"
              value={teamMemberBio}
              onChange={e => setTeamMemberBio(e.target.value)}
              fullWidth
              required
              multiline
              rows={5}
            />
            <TextField
              label="Linkedin"
              value={teamMemberLinkedin}
              onChange={e => setTeamMemberLinkedin(e.target.value)}
              fullWidth
            />
            <TextField
              label="Twitter"
              value={teamMemberTwitter}
              onChange={e => setTeamMemberTwitter(e.target.value)}
              fullWidth
            />
            {!loading ? (
              <Button
                fullWidth
                size="large"
                type="submit"
                color="inherit"
                onClick={handleSubmit}
                variant="outlined"
              >
                {data ? 'Update' : 'Submit'} Team Member
              </Button>
            ) : (
              <Button
                fullWidth
                size="large"
                color="inherit"
                variant="outlined"
              >
                <CircularProgress color="inherit" size={20} />
                &nbsp; {data ? 'Updating' : 'Creating'} Team Member
              </Button>
            )}
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
