import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CreateJobForm from 'src/sections/job/CreateJobForm';
import { useMemo, useState } from 'react';
import { JobService } from 'src/api';
import MessageAlert from 'src/components/widgets/MessageAlert';
import { connect } from 'react-redux';

const EditJob = ({ userId }) => {
  const nav = useNavigate();
  const { slug } = useParams();

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);

  useMemo(() => {
    if (slug) {
      async function getPost() {
        try {
          setLoading(true);
          const result = await JobService.getSingleJob(slug);
          setData(result.data?.data);
        } catch (error) {
          setError(
            error.response?.data?.message ||
              error.message ||
              'Unknown error has occured',
          );
        } finally {
          setLoading(false);
        }
      }
      getPost();
    }
  }, [slug]);

  useMemo(() => {
    if (submittedData) {
      async function editPost() {
        try {
          setEditLoading(true);
          const result = await JobService.updateJob(
            slug,
            submittedData,
          );
          if (result.data?.successMessage) nav('../jobs');
        } catch (error) {
          setError(
            error.response?.data?.message ||
              error.response?.data?.invalidToken ||
              error.response?.data?.postUpdateError ||
              error.response?.data?.unauthorizedError ||
              error.message ||
              'Unknown error has occured',
          );
        } finally {
          setEditLoading(false);
        }
      }
      editPost();
    }
  }, [submittedData]);

  return (
    <>
      <Helmet>
        <title> Edit Job | TGHrC</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" mb={4}>
          <Link to="../jobs">
            <IconButton aria-label="delete" size="medium">
              <ArrowBack fontSize="small" />
            </IconButton>
          </Link>
          <Typography variant="h4">Edit Job</Typography>
        </Stack>
        <MessageAlert
          state={{
            error,
            loading:
              loading && !data
                ? 'Loading post, please wait...'
                : null,
          }}
        />
        {data && !loading && (
          <CreateJobForm
            data={data}
            loading={editLoading}
            onCreate={data => {
              setError(null);
              setSubmittedData(data);
            }}
          />
        )}
      </Container>
    </>
  );
};

const mapStateToProps = state => ({
  userId: state.auth.isAuthenticated.currentUser._id,
});

export default connect(mapStateToProps)(EditJob);
