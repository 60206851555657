import { Delete, Edit } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Avatar,
  Card,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import ModalDialog from 'src/components/Global/ModalDialog';

const CompanyCard = ({ company, onEdit, onDelete, onPublish }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      sx={{ position: 'relative', mt: 3 }}
    >
      <Avatar
        src={company.companyLogo}
        alt={company.companyName}
        sx={{
          width: 70,
          height: 70,
          position: 'absolute',
          zIndex: 1,
          top: '-10px',
          left: 'calc(50% - 35px)',
        }}
      />
      <Card
        sx={{ borderRadius: 2.5, p: 1.5, pt: 5, textAlign: 'center' }}
        elevation={3}
      >
        <Typography variant="h6" mb={1}>
          {company.companyName}
        </Typography>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <IconButton
            size="small"
            color="success"
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_CAREERS_URL}/company/${company.slug}`,
                '_blank',
              )
            }
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton size="small" color="primary" onClick={onEdit}>
            <Edit />
          </IconButton>
          <IconButton size="small" color="error" onClick={handleOpen}>
            <Delete />
          </IconButton>
        </Stack>
        <ModalDialog
          title="Delete company?"
          subTitle="Are you sure do you want to delete this company?"
          open={open}
          item={company.name}
          handleClose={handleClose}
          handleClickOk={() => {
            handleClose();
            onDelete();
          }}
        />
      </Card>
    </Grid>
  );
};

export default CompanyCard;
