import React from 'react';
// @mui
import { List } from '@mui/material';
import CandidateTile from './CandidateTile';

const CandidatesList = ({
  candidates,
  onView,
  disabled,
}) => {
  return (
    <List>
      {candidates.map((item, index) => {
        const candidate = {
          id: item._id,
          name: item?.candidate?.firstName + ' ' + item?.candidate?.lastName,
        };
        const isDisabled = disabled === candidate.id;

        return (
          <CandidateTile
            onView={id => onView(id)}
            candidate={item?.candidate}
            isDisabled={isDisabled}
            key={index}
          />
        );
      })}
    </List>
  );
};

export default CandidatesList;
