import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Typography,
  TextField,
  Alert,
  CircularProgress,
  Divider,
  Stack,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Iconify from '../../components/iconify';
import { useEffect, useState, useMemo } from 'react';
import { ADD_RESOURCE, EDIT_RESOURCE } from 'src/redux/actionTypes';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import ChooseFileImage from 'src/components/Global/files/ChooseFileImage';
import ChooseFileField from 'src/components/Global/ChooseFileField';
import { useFetcher } from 'src/api/fetcher';

CreateResourceSidebar.propTypes = {
  openSidebar: PropTypes.bool,
  onOpenSidebar: PropTypes.func,
  onCloseSidebar: PropTypes.func,
};

export default function CreateResourceSidebar({
  openSidebar,
  onOpenSidebar,
  onCloseSidebar,
  onSubmit,
  errorApi,
  message,
  data,
  onEditSubmitted,
}) {
  const [resourceName, setResourceName] = useState('');
  const [resourceImage, setResourceImage] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [demoLink, setDemoLink] = useState('');
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [shouldUpload, setShouldUpload] = useState(true);

  const { data: categoriesData } = useFetcher(
    `/resources/getAllCategories`,
  );

  const categories = useMemo(() => {
    return categoriesData?.data || [];
  }, [categoriesData?.data]).map(item => {
    return {
      id: item._id,
      name: item.name,
    };
  });

  const handleSubmit = e => {
    e.preventDefault();
    setError();
    if (!resourceName || !resourceImage || !demoLink || !categoryId) {
      setError('All fields are required');
      return;
    }
    setError();
    setLoading(true);
    if (!data) {
      onSubmit({
        title: resourceName,
        category_id: categoryId,
        image: resourceImage,
        url: demoLink,
      });
    } else {
      const editedData = {
        title: resourceName,
        category_id: categoryId,
        image: resourceImage,
        url: demoLink,
      };
      onEditSubmitted(editedData);
    }
  };

  useEffect(() => {
    setLoading(false);
    if (message?.action === ADD_RESOURCE && !data) {
      setResourceName('');
      setCategoryId('');
      setResourceImage('');
      setDemoLink('');
    }
    if (message?.action === EDIT_RESOURCE) {
      setResourceName('');
      setCategoryId('');
      setResourceImage('');
      setDemoLink('');
    }
    if (data) {
      setResourceName(data.title);
      setCategoryId(data.category_id);
      setResourceImage(data.image);
      setDemoLink(data.url);
    } else {
      setResourceName('');
      setCategoryId('');
      setResourceImage('');
      setDemoLink('');
    }
  }, [message, data]);

  useEffect(() => {
    setError(
      errorApi?.action === ADD_RESOURCE ||
        errorApi?.action === EDIT_RESOURCE
        ? errorApi?.message
        : '',
    );
    setLoading(false);
  }, [errorApi]);

  return (
    <>
      <Button
        variant="contained"
        startIcon={<Iconify icon="eva:plus-fill" />}
        onClick={() => onOpenSidebar()}
      >
        New Resource
      </Button>

      <Drawer
        anchor="right"
        open={openSidebar}
        onClose={onCloseSidebar}
        PaperProps={{
          sx: { width: 450, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            {data ? 'Edit' : 'Create'} Resource
          </Typography>
          <IconButton onClick={onCloseSidebar}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        {error && (
          <Alert variant="standard" severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        <Divider />
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <TextField
              label="Resource Title"
              value={resourceName}
              onChange={e => setResourceName(e.target.value)}
              fullWidth
              required
            />
            <FormControl fullWidth sx={{ my: 1 }}>
              <InputLabel id="demo-simple-select-label">
                Resource Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={categoryId}
                onChange={e => setCategoryId(e.target.value)}
                label="Select Category"
              >
                {categories.map((item, index) => {
                  return (
                    <MenuItem value={item.id} key={index}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <ChooseFileImage
              title="Background Image"
              onSelect={setResourceImage}
              selected={resourceImage}
              error={error}
            />
            <FormControl sx={{ mt: 2 }}>
              <FormLabel>Resource File Options</FormLabel>
              <RadioGroup
                value={String(shouldUpload)}
                onChange={e =>
                  setShouldUpload(e.target.value === 'true')
                }
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Upload a document"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Add a link"
                />
              </RadioGroup>
            </FormControl>
            {shouldUpload ? (
              <ChooseFileField
                label="Upload Resource Document"
                acceptFileTypes="application/pdf"
                isMultiple={false}
                onChange={fileUrls => {
                  setDemoLink(fileUrls);
                }}
                currentItem={demoLink}
                currentItemUrl={demoLink}
                chipLabel="Resource Document"
              />
            ) : (
              <TextField
                label="Resource Link"
                value={demoLink}
                onChange={e => setDemoLink(e.target.value)}
                fullWidth
              />
            )}

            {!loading ? (
              <Button
                fullWidth
                size="large"
                type="submit"
                color="inherit"
                onClick={handleSubmit}
                variant="outlined"
              >
                {data ? 'Update' : 'Submit'} Resource
              </Button>
            ) : (
              <Button
                fullWidth
                size="large"
                color="inherit"
                variant="outlined"
              >
                <CircularProgress color="inherit" size={20} />
                &nbsp; {data ? 'Updating' : 'Creating'} Resource
              </Button>
            )}
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
