import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Box,
  Checkbox,
} from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import RichTextEditor from 'src/components/blog/RichTextEditor';
import { useMemo, useState, useRef } from 'react';
import { useFetcher } from 'src/api/fetcher';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const CreateJobForm = ({ onCreate, loading, data: editData }) => {
  const [showSalaryRange, setShowSalaryRange] = useState(false);
  const [showResponsibilities, setShowResponsibilities] =
    useState(false);
  const [showQualifications, setShowQualifications] = useState(false);
  const [showRequiredSkills, setShowRequiredSkills] = useState(false);
  const [title, setTitle] = useState('');
  const [typeId, setTypeId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [industryId, setIndustryId] = useState('');
  const [employmentStatusId, setEmploymentStatusId] = useState('');
  const [nberOfVacantPositions, setNberOfVacantPositions] =
    useState('');
  const [salaryRange, setSalaryRange] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [responsibilities, setResponsibilities] = useState([
    { name: '' },
  ]);
  const [qualifications, setQualifications] = useState([
    { name: '' },
  ]);
  const [requiredSkills, setRequiredSkills] = useState([
    { name: '' },
  ]);
  const [isPublic, setIsPublic] = useState(true);
  const [emailTalentCommunity, setEmailTalentCommunity] =
    useState(true);
  const [emailRejectedCandidates, setEmailRejectedCandidates] =
    useState(true);
  const [showCompanyOverview, setShowCompanyOverview] = useState(true)

  useMemo(() => {
    if (editData) {
      setTitle(editData.title);
      setTypeId(editData.type_id);
      setCategoryId(editData.category_id);
      setCompanyId(editData.company_id);
      setIndustryId(editData.industry_id);
      setEmploymentStatusId(editData.employmentStatus_id);
      setNberOfVacantPositions(editData.nberOfVacantPositions);
      setSalaryRange(editData.salaryRange);
      setJobDescription(editData.jobDescription);
      setResponsibilities(editData.responsibilities);
      setQualifications(editData.qualifications);
      setRequiredSkills(editData.requiredSkills);
      setIsPublic(editData.isPublic || true);
      setEmailTalentCommunity(editData.emailTalentCommunity || true);
      setEmailRejectedCandidates(
        editData.emailRejectedCandidates || true,
      );
      setShowCompanyOverview(
        editData.showCompanyOverview || true,
      );
    }
  }, [editData]);

  //Errors
  const [formError, setFormError] = useState(null);

  const { data: typesData } = useFetcher(`/job/getAllJobTypes`);

  const { data: categoriesData } = useFetcher(
    `/job/getAllCategories`,
  );

  const { data: companyData } = useFetcher(`/job/getAllCompanies`);
  
  const { data: industryData } = useFetcher(`/job/getAllJobIndustries`);

  const { data: employmentStatusData } = useFetcher(`/job/getAllJobEmploymentStatuses`);

  const categories = useMemo(() => {
    return categoriesData?.data || [];
  }, [categoriesData?.data]).map(item => {
    return {
      id: item._id,
      name: item.name,
    };
  });

  const companies = useMemo(() => {
    return companyData?.data || [];
  }, [companyData?.data]).map(item => {
    return {
      id: item._id,
      name: item.companyName,
    };
  });

  const types = useMemo(() => {
    return typesData?.data || [];
  }, [typesData?.data]).map(item => {
    return {
      id: item._id,
      name: item.type,
    };
  });

  const industries = useMemo(() => {
    return industryData?.data || [];
  }, [industryData?.data]).map(item => {
    return {
      id: item._id,
      name: item.industry,
    };
  });

  const employmentStatuses = useMemo(() => {
    return employmentStatusData?.data || [];
  }, [employmentStatusData?.data]).map(item => {
    return {
      id: item._id,
      name: item.status,
    };
  });
  console.log(formError);
  const handleSubmit = e => {
    e.preventDefault();
    const validation = {
      title: !title ? 'Title required' : false,
      typeId: !typeId ? 'Type ID required' : false,
      categoryId: !categoryId ? 'Category ID required' : false,
      companyId: !companyId ? 'Company ID required' : false,
      industryId: !industryId ? 'Industry ID required' : false,
      employmentStatusId: !employmentStatusId ? 'Employment Status ID required' : false,
      nberOfVacantPositions: !nberOfVacantPositions
        ? 'Openings required'
        : false,
      jobDescription: !jobDescription
        ? 'Job description required'
        : false,
    };

    if (!Object.values(validation).every(val => !val)) {
      setFormError(validation);
      return;
    }

    let jobData = {
      title,
      type_id: typeId,
      category_id: categoryId,
      company_id: companyId,
      industry_id: industryId,
      employmentStatus_id: employmentStatusId,
      nberOfVacantPositions,
      jobDescription,
      isPublic,
      emailTalentCommunity,
      emailRejectedCandidates,
      showCompanyOverview,
    };

    // if (salaryRange.trim() !== '') {
    //   jobData.salaryRange = salaryRange;
    // }

    // if (responsibilities[0].name.trim() !== '') {
    //   jobData.responsibilities = responsibilities;
    // }

    // if (qualifications[0].name.trim() !== '') {
    //   jobData.qualifications = qualifications;
    // }

    // if (requiredSkills[0].name.trim() !== '') {
    //   jobData.requiredSkills = requiredSkills;
    // }

    if (editData) {
      onCreate(jobData);
      return;
    }
    onCreate(jobData);
  };

  const handleAddResponsibility = () => {
    setResponsibilities([...responsibilities, { name: '' }]);
  };

  const handleAddQualification = () => {
    setQualifications([...qualifications, { name: '' }]);
  };

  const handleAddRequiredSkill = () => {
    setRequiredSkills([...requiredSkills, { name: '' }]);
  };

  const handleResponsibilityChange = (e, index) => {
    const newResponsibilities = [...responsibilities];
    newResponsibilities[index].name = e.target.value;
    setResponsibilities(newResponsibilities);
  };

  const handleQualificationChange = (e, index) => {
    const newQualifications = [...qualifications];
    newQualifications[index].name = e.target.value;
    setQualifications(newQualifications);
  };

  const handleRequiredSkillChange = (e, index) => {
    const newRequiredSkills = [...requiredSkills];
    newRequiredSkills[index].name = e.target.value;
    setRequiredSkills(newRequiredSkills);
  };

  const handleRemoveResponsibility = index => {
    setResponsibilities(
      responsibilities.filter((_, i) => i !== index),
    );
  };

  const handleRemoveQualification = index => {
    setQualifications(qualifications.filter((_, i) => i !== index));
  };

  const handleRemoveRequiredSkill = index => {
    setRequiredSkills(requiredSkills.filter((_, i) => i !== index));
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        label="Job Title"
        color="secondary"
        fullWidth
        sx={{ my: 1 }}
        value={title}
        onChange={e => setTitle(e.target.value)}
        error={Boolean(formError?.title)}
      />
      <FormControl fullWidth sx={{ my: 1 }}>
        <InputLabel id="demo-simple-select-label">
          Job Type
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={typeId}
          onChange={e => setTypeId(e.target.value)}
          label="Select Type"
          error={Boolean(formError?.typeId)}
        >
          {types.map((item, index) => {
            return (
              <MenuItem value={item.id} key={index}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ my: 1 }}>
        <InputLabel id="demo-simple-select-label">
          Job Level
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={categoryId}
          onChange={e => setCategoryId(e.target.value)}
          label="Select Category"
          error={Boolean(formError?.categoryId)}
        >
          {categories.map((item, index) => {
            return (
              <MenuItem value={item.id} key={index}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ my: 1 }}>
        <InputLabel id="demo-simple-select-label">
          Hiring Company
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={companyId}
          onChange={e => setCompanyId(e.target.value)}
          label="Select Company"
          error={Boolean(formError?.companyId)}
        >
          {companies.map((item, index) => {
            return (
              <MenuItem value={item.id} key={index}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ my: 1 }}>
        <InputLabel id="demo-simple-select-label">
          Industry
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={industryId}
          onChange={e => setIndustryId(e.target.value)}
          label="Select Industry"
          error={Boolean(formError?.industryId)}
        >
          {industries.map((item, index) => {
            return (
              <MenuItem value={item.id} key={index}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ my: 1 }}>
        <InputLabel id="demo-simple-select-label">
          Employment Status
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={employmentStatusId}
          onChange={e => setEmploymentStatusId(e.target.value)}
          label="Select Company"
          error={Boolean(formError?.employmentStatusId)}
        >
          {employmentStatuses.map((item, index) => {
            return (
              <MenuItem value={item.id} key={index}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Typography variant="body2" sx={{ my: 1 }}>
        Job Description
      </Typography>
      <RichTextEditor
        onChange={html => setJobDescription(html)}
        placeholder="Start typing blog details..."
        html={jobDescription}
      />
      {formError?.jobDescription && (
        <Typography variant="caption" color="error">
          {formError.jobDescription}
        </Typography>
      )}

      <div></div>

      <TextField
        label="Openings"
        type='number'
        color="secondary"
        fullWidth
        sx={{ mt: 2 }}
        value={nberOfVacantPositions}
        onChange={e => setNberOfVacantPositions(e.target.value)}
        error={Boolean(formError?.nberOfVacantPositions)}
      />

      <div></div>
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={showSalaryRange}
            onChange={() => setShowSalaryRange(!showSalaryRange)}
          />
        }
        label="Add Salary Range"
        sx={{ ml: -1, mt: 2 }}
      /> */}
      {showSalaryRange && (
        <TextField
          label="Salary Range"
          color="secondary"
          fullWidth
          sx={{ mt: 1 }}
          value={salaryRange}
          onChange={e => setSalaryRange(e.target.value)}
          error={Boolean(formError?.salaryRange)}
        />
      )}
      <div></div>
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={showResponsibilities}
            onChange={() =>
              setShowResponsibilities(!showResponsibilities)
            }
          />
        }
        label="Add Job Responsibilities"
        sx={{ ml: -1, mt: 1 }}
      /> */}
      {showResponsibilities && (
        <>
          <Typography variant="body2" sx={{ mb: 1, mt: 1 }}>
            Job Responsibilities
          </Typography>
          <Box
            sx={{
              border: '1px solid grey',
              p: 2,
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              {responsibilities.map((responsibility, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    gap: '10px',
                  }}
                >
                  <TextField
                    label={`Responsibility ${index + 1}`}
                    fullWidth
                    value={responsibility.name}
                    onChange={e =>
                      handleResponsibilityChange(e, index)
                    }
                  />
                  {index !== 0 && (
                    <Button
                      onClick={() =>
                        handleRemoveResponsibility(index)
                      }
                    >
                      <RemoveCircleIcon
                        sx={{
                          color: 'error.main',
                          width: 36,
                          height: 36,
                        }}
                      />
                    </Button>
                  )}
                </div>
              ))}
            </Box>
            <Button
              sx={{ alignSelf: 'flex-end' }}
              variant="outlined"
              startIcon={<AddCircleIcon />}
              onClick={handleAddResponsibility}
            >
              Add Responsibility
            </Button>
          </Box>
        </>
      )}

      <div></div>
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={showQualifications}
            onChange={() =>
              setShowQualifications(!showQualifications)
            }
          />
        }
        label="Add Job Qualifications"
        sx={{ ml: -1, mt: 1 }}
      /> */}
      {showQualifications && (
        <>
          <Typography variant="body2" sx={{ my: 1 }}>
            Job Qualifications
          </Typography>
          <Box
            sx={{
              border: '1px solid grey',
              p: 2,
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              {qualifications.map((qualification, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    gap: '10px',
                  }}
                >
                  <TextField
                    label={`Qualification ${index + 1}`}
                    fullWidth
                    value={qualification.name}
                    onChange={e =>
                      handleQualificationChange(e, index)
                    }
                  />
                  {index !== 0 && (
                    <Button
                      onClick={() => handleRemoveQualification(index)}
                    >
                      <RemoveCircleIcon
                        sx={{
                          color: 'error.main',
                          width: 36,
                          height: 36,
                        }}
                      />
                    </Button>
                  )}
                </div>
              ))}
            </Box>
            <Button
              sx={{ alignSelf: 'flex-end' }}
              variant="outlined"
              startIcon={<AddCircleIcon />}
              onClick={handleAddQualification}
            >
              Add Qualification
            </Button>
          </Box>
        </>
      )}

      <div></div>
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={showRequiredSkills}
            onChange={() =>
              setShowRequiredSkills(!showRequiredSkills)
            }
          />
        }
        label="Add Required Skills"
        sx={{ ml: -1, mt: 1 }}
      /> */}
      {showRequiredSkills && (
        <>
          <Typography variant="body2" sx={{ my: 1 }}>
            Required Skills
          </Typography>
          <Box
            sx={{
              border: '1px solid grey',
              p: 2,
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              {requiredSkills.map((requiredSkill, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    gap: '10px',
                  }}
                >
                  <TextField
                    label={`Required Skill ${index + 1}`}
                    fullWidth
                    value={requiredSkill.name}
                    onChange={e =>
                      handleRequiredSkillChange(e, index)
                    }
                  />
                  {index !== 0 && (
                    <Button
                      onClick={() => handleRemoveRequiredSkill(index)}
                    >
                      <RemoveCircleIcon
                        sx={{
                          color: 'error.main',
                          width: 36,
                          height: 36,
                        }}
                      />
                    </Button>
                  )}
                </div>
              ))}
            </Box>
            <Button
              sx={{ alignSelf: 'flex-end' }}
              variant="outlined"
              startIcon={<AddCircleIcon />}
              onClick={handleAddRequiredSkill}
            >
              Add Skill
            </Button>
          </Box>
        </>
      )}

      <div></div>

      <FormControl sx={{ my: 2 }}>
        <FormLabel>Visibility Options</FormLabel>
        <RadioGroup
          value={isPublic}
          onChange={e => setIsPublic(e.target.value)}
        >
          <FormControlLabel
            value={true}
            control={<Radio />}
            label="Publish immediately"
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label="Save privately"
          />
        </RadioGroup>
      </FormControl>

      <div></div>

      <FormControl sx={{ mb: 2 }}>
        <FormLabel>Talent Community Options</FormLabel>
        <RadioGroup
          value={emailTalentCommunity}
          onChange={e => setEmailTalentCommunity(e.target.value)}
        >
          <FormControlLabel
            value={true}
            control={<Radio />}
            label="Notify all talent community members"
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label="Do not notify any talent community member"
          />
        </RadioGroup>
      </FormControl>

      <div></div>

      <FormControl sx={{ mb: 1 }}>
        <FormLabel>Rejected Candidates Options</FormLabel>
        <RadioGroup
          value={emailRejectedCandidates}
          onChange={e => setEmailRejectedCandidates(e.target.value)}
        >
          <FormControlLabel
            value={true}
            control={<Radio />}
            label="Notify all rejected candidates"
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label="Do not notify any rejected candidate"
          />
        </RadioGroup>
      </FormControl>

      <div></div>

      <FormControl sx={{ my: 1 }}>
        <FormLabel>Hiring Company Overview Options</FormLabel>
        <RadioGroup
          value={showCompanyOverview}
          onChange={e => setShowCompanyOverview(e.target.value)}
        >
          <FormControlLabel
            value={true}
            control={<Radio />}
            label="Show Hiring Company Overview"
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label="Do not show Hiring Company Overview"
          />
        </RadioGroup>
      </FormControl>
      <div></div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          size="large"
          endIcon={
            loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <KeyboardArrowRight />
            )
          }
        >
          {editData ? 'Edit' : 'Create'} Job
        </Button>
      </Box>
    </form>
  );
};

export default CreateJobForm;
