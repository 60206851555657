import { AxiosError } from 'axios';
import { JobService } from 'src/api';
import * as actions from '../actionTypes';

export const getAllCompanies = () => {
  return async dispatch => {
    try {
      const result = await JobService.getAllCompanies();
      if (!result.data.data) {
        throw new Error('Failed to retrieve companies');
      }
      dispatch({
        type: actions.GET_COMPANIES,
        payload: {
          companies: result.data.data,
        },
      });
    } catch (error) {
      dispatch({
        type: actions.COMPANY_ERROR,
        payload: {
          error: {
            action: actions.GET_COMPANIES,
            message:
              error.message === 'Network Error'
                ? 'Server unreachable, please check your internet connection and try again'
                : 'Something went wrong while retrieving companies, try again later',
            at: new Date(),
          },
        },
      });
    }
  };
};

export const createCompany = data => async dispatch => {
  try {
    const response = await JobService.createCompany(data);
    dispatch({
      type: actions.CREATE_COMPANY,
      payload: {
        message: {
          action: actions.CREATE_COMPANY,
          success: response.data.successMessage,
        },
        company: response.data.data,
      },
    });
  } catch (error) {
    dispatch({
      type: actions.COMPANY_ERROR,
      payload: {
        error: {
          action: actions.CREATE_COMPANY,
          message: error.message,
          at: new Date(),
        },
      },
    });
  }
};

export const updateCompany = (id, data) => async dispatch => {
  try {
    const response = await JobService.updateCompany(id, data);
    dispatch({
      type: actions.UPDATE_COMPANY,
      payload: {
        message: {
          action: actions.UPDATE_COMPANY,
          success: response.data.successMessage,
        },
        company: response.data.data,
      },
    });
  } catch (error) {
    dispatch({
      type: actions.COMPANY_ERROR,
      payload: {
        error: {
          action: actions.UPDATE_COMPANY,
          message: error.message,
          at: new Date(),
        },
      },
    });
  }
};

export const deleteCompany = id => async dispatch => {
  try {
    const response = await JobService.deleteCompany(id);
    dispatch({
      type: actions.DELETE_COMPANY,
      payload: {
        message: {
          action: actions.DELETE_COMPANY,
          success: response.data.successMessage,
        },
        id,
      },
    });
  } catch (error) {
    dispatch({
      type: actions.COMPANY_ERROR,
      payload: {
        error: {
          action: actions.DELETE_COMPANY,
          message: error.message,
          at: new Date(),
        },
      },
    });
  }
};

export const getSingleCompany = id => async dispatch => {
  try {
    const response = await JobService.getSingleCompany(id);
    dispatch({
      type: actions.GET_COMPANY,
      payload: {
        message: {
          action: actions.GET_COMPANY,
          success: response.data.successMessage,
        },
        company: response.data.data,
      },
    });
  } catch (error) {
    dispatch({
      type: actions.COMPANY_ERROR,
      payload: {
        error: {
          action: actions.GET_COMPANY,
          message: error.message,
          at: new Date(),
        },
      },
    });
  }
};