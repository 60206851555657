import * as actions from '../actionTypes';
import { ResourceService } from 'src/api';

export const getAllResources = () => {
  return async dispatch => {
    try {
      const result = await ResourceService.getAllResources();
      if (!result.data.data) {
        throw new Error('Failed to retrieve resources');
      }

      dispatch({
        type: actions.GET_ALL_RESOURCES,
        payload: {
          resources: result.data.data,
          paginationDetails: result.data.paginationDetails,
        },
      });
    } catch (error) {
      dispatch({
        type: actions.RESOURCE_ERROR,
        payload: {
          error: {
            action: actions.GET_ALL_RESOURCES,
            message: error.message,
            at: new Date(),
          },
        },
      });
    }
  };
};

export const addResource = data => async dispatch => {
  try {
    const response = await ResourceService.addResource(data);
    dispatch({
      type: actions.ADD_RESOURCE,
      payload: {
        message: {
          action: actions.ADD_RESOURCE,
          success: response.data.successMessage,
        },
        resource: response.data.resourceContent,
      },
    });
  } catch (error) {
    dispatch({
      type: actions.RESOURCE_ERROR,
      payload: {
        error: {
          action: actions.ADD_RESOURCE,
          message: error.message,
          at: new Date(),
        },
      },
    });
  }
};

export const editResource = (id, data) => async dispatch => {
  try {
    const response = await ResourceService.updateResource(id, data);
    dispatch({
      type: actions.EDIT_RESOURCE,
      payload: {
        message: {
          action: actions.EDIT_RESOURCE,
          success: response.data.resourceUpdateSuccess,
        },
        resource: response.data.resourceContent,
      },
    });
  } catch (error) {
    dispatch({
      type: actions.RESOURCE_ERROR,
      payload: {
        error: {
          action: actions.EDIT_RESOURCE,
          message: error.message,
          at: new Date(),
        },
      },
    });
  }
};

export const deleteResource = id => async dispatch => {
  try {
    const response = await ResourceService.deleteResource(id);
    dispatch({
      type: actions.DELETE_RESOURCE,
      payload: {
        message: {
          action: actions.DELETE_RESOURCE,
          success: response.data.successMessage,
        },
        id,
      },
    });
  } catch (error) {
    dispatch({
      type: actions.RESOURCE_ERROR,
      payload: {
        error: {
          action: actions.DELETE_RESOURCE,
          message: error.message,
          at: new Date(),
        },
      },
    });
  }
};