import API from './_api_';

// ADD RESOURCE
const addResource = data => API.post('/resources/addResource', data);

// GET ALL RESOURCES
const getAllResources = () => API.get('/resources/getAllResources?all=admin');

// GET SINGLE RESOURCE
const getSingleResource = id =>
  API.get('/resources/getSingleResource?resourceId=' + id);

// UPDATE RESOURCE
const updateResource = (id, data) =>
  API.patch('/resources/updateResource?resourceId=' + id, data);

// DELETE RESOURCE
const deleteResource = id =>
  API.delete('/resources/deleteResource?resourceId=' + id);

// Category endpoints
const addCategory = data => API.post('/resources/addCategory', data);
const getAllCategories = () => API.get('/resources/getAllCategories');
const updateCategory = (id, name) =>
  API.put('/resources/updateCategory/' + id, { name });
const deleteCategory = id =>
  API.delete('/resources/deleteCategory/' + id);

export default {
  addResource,
  getAllResources,
  getSingleResource,
  updateResource,
  deleteResource,
  addCategory,
  getAllCategories,
  updateCategory,
  deleteCategory,
};
