import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import jwt_decode from 'jwt-decode';
import { AuthService } from 'src/api';
import { toast } from 'react-hot-toast';
import Logo from 'src/components/logo/Logo';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

const initFormData = {
  password: '',
  repeatPassword: '',
};

const initState = { loading: false, error: null };

const SetPasswordPage = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [formData, setFormData] = useState(initFormData);
  const [state, setState] = useState(initState);

  const handleSubmit = async e => {
    e.preventDefault();
    if (state.loading) return;
    setState(initState);
    try {
      await toast.promise(AuthService.setPassword(token, formData), {
        loading: `Setting passowrd, please wait...`,
        success: `Password set successfully!`,
        error: error => {
          if (error.response) {
            return (
              error?.response?.data?.errorMessage ||
              error?.response?.data?.validationError ||
              error?.response?.data?.invalidEmail ||
              error.message ||
              'Unknown error occured, please try again.'
            );
          } else {
            return 'Something went wrong while logging in, please try again';
          }
        },
      });
      navigate('/');
      throw new Error(
        'Login went successfully but data being returned is unprecised',
      );
    } catch (error) {
      setState(prev => ({
        ...prev,
        error:
          error?.response?.data?.errorMessage ||
          error?.response?.data?.validationError ||
          error?.response?.data?.invalidEmail ||
          error.message ||
          'Unknown error occured, please try again.',
      }));
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const onChange = e => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={2}
          sx={{
            margin: '0px auto',
            backgroundColor: '#ffffff',
            border: '1px solid #d2d2d2',
            borderRadius: 1,
            padding: 4,
            width: {
              xs: '100%',
              lg: '35%',
            },
          }}
        >
          <Logo />
          <Typography gutterBottom variant="h5" textAlign="center">
            Set Account Password
          </Typography>

          <FormControl required fullWidth>
            <InputLabel htmlFor="outlined-adornment-password-login">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password-login"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              name="password"
              label="Password"
              required
              fullWidth
              onChange={onChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{}}
            />
          </FormControl>

          <FormControl required fullWidth>
            <InputLabel htmlFor="outlined-adornment-password-login">
              Repeat Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password-login"
              type={showRepeatPassword ? 'text' : 'password'}
              value={formData.repeatPassword}
              name="repeatPassword"
              label="Repeat Password"
              required
              fullWidth
              onChange={onChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowRepeatPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {showRepeatPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{}}
            />
          </FormControl>
          {state.error && (
            <Typography variant="body2" color="error">
              {state.error}
            </Typography>
          )}
          <Button
            type="submit"
            size="large"
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: '#FF8911',
              '&:hover': { backgroundColor: '#FF8911' },
              mt: 2,
            }}
          >
            Set Password
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

export default SetPasswordPage;
