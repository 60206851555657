import { combineReducers } from 'redux';
import authReducer from './auth';
import staffMemberReducer from './member';
import projectReducer from './project';
import serviceReducer from './service';
import companyReducer from './company';
import userReducer from './user';
import paymentReducer from './payment';
import messageReducer from './message';
import testimonialReducer from './testimony';
import faqReducer from './faq';
import resourceReducer from './resource';
import teamReducer from './team'
import communityReducer from './community';

export default combineReducers({
  project: projectReducer,
  service: serviceReducer,
  member: staffMemberReducer,
  company: companyReducer,
  user: userReducer,
  community: communityReducer,
  auth: authReducer,
  payment: paymentReducer,
  message: messageReducer,
  testimonial: testimonialReducer,
  faq: faqReducer,
  resource: resourceReducer,
  team: teamReducer,
});
