import PropTypes from 'prop-types';
import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Card,
  Grid,
  Typography,
  CardContent,
  Box,
  Stack,
  IconButton,
  MenuItem,
  Popover,
  Menu,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalDialog from 'src/components/Global/ModalDialog';
import Iconify from 'src/components/iconify/Iconify';
import { format, parseISO } from 'date-fns';
import CandidatesSidebar from './CandidateSidebar';

TrainingCard.propTypes = {
  training: PropTypes.object.isRequired,
};

const StyledInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  marginTop: theme.spacing(2),
  color: theme.palette.text.disabled,
}));

export default function TrainingCard({
  training,
  onDelete,
  onPublish,
  setAllCandidates,
  setOpenSidebar
}) {
  const nav = useNavigate();

  const TRAINING_INFO = [
    {
      label: training?.startDate
        ? format(parseISO(training.startDate), 'yyyy-MM-dd')
        : '',
      icon: 'eva:calendar-outline',
    },
    {
      label: training?.endDate
        ? format(parseISO(training.endDate), 'yyyy-MM-dd')
        : '',
      icon: 'eva:calendar-outline',
    },
  ];

  const [open, setOpen] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenMenu = event => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={{ position: 'relative' }}>
          <CardContent
            sx={{
              pt: 4,
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="start"
              spacing={2}
            >
              <Typography
                color="inherit"
                variant="h5"
                underline="hover"
                href={`${process.env.REACT_APP_WEB_URL}/training/${training?.slug}`}
                target="_blank"
                component="a"
                sx={{ textDecoration: 'none' }}
              >
                {training?.name}
              </Typography>
              <IconButton onClick={handleOpenMenu}>
                <Iconify
                  icon="eva:more-vertical-fill"
                  sx={{ width: 18, height: 18 }}
                />
              </IconButton>
            </Stack>
            <StyledInfo>
              {TRAINING_INFO.map((info, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ml: index === 0 ? 0 : 1.5,
                  }}
                >
                  <Iconify
                    icon={info.icon}
                    sx={{ width: 16, height: 16, mr: 0.5 }}
                  />
                  <Typography variant="p" fontSize={14}>
                    {info.label}
                  </Typography>
                </Box>
              ))}
            </StyledInfo>
          </CardContent>
        </Card>
      </Grid>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          sx={{ color: 'primary.main' }}
          href={`${process.env.REACT_APP_WEB_URL}/training/${training?.slug}`}
          target="_blank"
          component="a"
        >
          <Iconify icon={'eva:eye-fill'} sx={{ mr: 2 }} />
          Preview
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpen(null);
            setOpenSidebar(true);
            setAllCandidates(training?.appliedCandidates);
          }}
        >
          <Iconify icon={'bi:people-fill'} sx={{ mr: 2 }} />
          Candidates
        </MenuItem>
        <MenuItem
          sx={{ color: 'success.main' }}
          onClick={() => {
            setOpen(null);
            onPublish();
          }}
        >
          <Iconify icon={'eva:external-link-fill'} sx={{ mr: 2 }} />
          {training?.isPublic === true ? 'Unpublish' : 'Publish'}
        </MenuItem>
        <MenuItem
          onClick={() =>
            nav(`/dashboard/trainings/edit/${training?.slug}`)
          }
        >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={handleOpenModal}
        >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      <ModalDialog
        title="Delete Training?"
        subTitle={`Are you sure do you want to delete this training?`}
        item={training?.name}
        open={openModal}
        handleClose={handleCloseModal}
        handleClickOk={() => {
          handleCloseModal();
          onDelete();
        }}
      />
    </>
  );
}
