import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { ADD_USER, EDIT_USER } from 'src/redux/actionTypes';

let FEATURES = [
  { label: 'All', value: 'All' },
  { label: 'Users', value: 'Users' },
  { label: 'Careers', value: 'Careers' },
  { label: 'Trainings', value: 'Trainings' },
  { label: 'Talent Community', value: 'Talent Community' },
  { label: 'Projects', value: 'Projects' },
  { label: 'Faqs', value: 'Faqs' },
  { label: 'About', value: 'About' },
  { label: 'Services', value: 'Services' },
  { label: 'Blogs', value: 'Blogs' },
  { label: 'Gallery', value: 'Gallery' },
  { label: 'Staff Members', value: 'Staff Members' },
  { label: 'Testimonials', value: 'Testimonials' },
  { label: 'Resources', value: 'Resources' },
  { label: 'Messages', value: 'Messages' },
];

CreateUserSidebar.propTypes = {
  openSidebar: PropTypes.bool,
  onOpenSidebar: PropTypes.func,
  onCloseSidebar: PropTypes.func,
};

export default function CreateUserSidebar({
  openSidebar,
  onOpenSidebar,
  onCloseSidebar,
  onSubmit,
  data,
  errorApi,
  message,
}) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [allowedFeatures, setAllowedFeatures] = useState([]);

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setError();
    if (
      !firstName ||
      !lastName ||
      !email ||
      allowedFeatures.length === 0
    ) {
      setError('All fields are required');
      return;
    }

    setError();
    setLoading(true);
    const userData = {
      firstName,
      lastName,
      email,
      allowedFeatures,
    };
    if (!data) {
      onSubmit({
        create: true,
        data: { ...userData },
      });
    } else {
      onSubmit({
        create: false,
        data: userData,
      });
    }
  };

  useEffect(() => {
    setLoading(false);
    if (message?.action === ADD_USER && !data) {
      setFirstName('');
      setLastName('');
      setEmail('');
      setAllowedFeatures([]);
    }
    if (message?.action === EDIT_USER) {
      setFirstName('');
      setLastName('');
      setEmail('');
      setAllowedFeatures([]);
    }
    if (data) {
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setEmail(data.email);
      setAllowedFeatures(data.allowedFeatures);
    } else {
      setFirstName('');
      setLastName('');
      setEmail('');
      setAllowedFeatures([]);
    }
  }, [data, message]);

  useEffect(() => {
    setError(
      errorApi?.action === ADD_USER || errorApi?.action === EDIT_USER
        ? errorApi?.message
        : '',
    );
    setLoading(false);
  }, [errorApi]);

  return (
    <>
      <Button
        onClick={onOpenSidebar}
        variant="outlined"
        color="secondary"
        startIcon={<AddCircleOutlineOutlined />}
      >
        Create New User
      </Button>

      <Drawer
        anchor="right"
        open={openSidebar}
        onClose={onCloseSidebar}
        PaperProps={{
          sx: { width: 350, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            {data ? 'Update' : 'Add'} User
          </Typography>
          <IconButton onClick={onCloseSidebar}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
        {error && (
          <Alert variant="standard" severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <TextField
              label="First Name"
              color="secondary"
              fullWidth
              required
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />

            <TextField
              label="Last Name"
              color="secondary"
              fullWidth
              required
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />

            <TextField
              label="Email"
              color="secondary"
              fullWidth
              required
              value={email}
              onChange={e => setEmail(e.target.value)}
            />

            <FormControl fullWidth sx={{ my: 1 }}>
              <InputLabel id="to">Allowed Features *</InputLabel>
              <Select
                labelId="Allowed Features *"
                id="to-select"
                multiple
                value={allowedFeatures}
                onChange={e => {
                  if (e.target.value.includes('All')) {
                    setAllowedFeatures(['All']);
                  } else {
                    setAllowedFeatures(e.target.value);
                  }
                }}
                label="Select Receivers"
              >
                {FEATURES.map(item => {
                  return (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          {!loading ? (
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              onClick={handleSubmit}
              variant="outlined"
            >
              {data ? 'Update' : 'Submit'}
            </Button>
          ) : (
            <Button
              fullWidth
              size="large"
              color="inherit"
              variant="outlined"
            >
              <CircularProgress color="inherit" size={20} />
              &nbsp; {data ? 'Updating' : 'Creating'}
            </Button>
          )}
        </Box>
      </Drawer>
    </>
  );
}
