import {
  GET_COMPANIES,
  CREATE_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY,
  GET_COMPANY,
  COMPANY_ERROR,
} from '../actionTypes';

const initCompanyState = {
  error: null,
  loading: true,
  companies: [],
};

const companyReducer = (state = initCompanyState, action) => {
  switch (action.type) {
    case GET_COMPANIES:
      return {
        ...state,
        loading: false,
        companies: [...action.payload.companies],
      };
    case CREATE_COMPANY:
      return {
        ...state,
        error: null,
        message: action.payload.message,
        companies: [
          action.payload.company,
          ...state.companies,
        ],
      };
    case UPDATE_COMPANY:
      return {
        ...state,
        error: null,
        message: action.payload.message,
        companies: state.companies.map(company =>
          company._id !== action.payload.company._id
            ? company
            : action.payload.company,
        ),
      };
    case DELETE_COMPANY:
      return {
        ...state,
        error: null,
        message: action.payload.message,
        companies: state.companies.filter(
          company => company._id !== action.payload.id,
        ),
      };
    case GET_COMPANY:
      return {
        ...state,
        error: null,
        message: action.payload.message,
        companies: state.companies.map(company =>
          company._id !== action.payload.company._id
            ? company
            : action.payload.company,
        ),
      };
    case COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default companyReducer;