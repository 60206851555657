import React from 'react';
// @mui
import { List } from '@mui/material';
import TypeTile from './TypeTile';

const TypesList = ({
  types,
  onEdit,
  onDelete,
  disabled,
}) => {
  return (
    <List>
      {types.map((item, index) => {
        const type = {
          id: item._id,
          type: item.type,
        };
        const isDisabled = disabled === type.id;

        return (
          <TypeTile
            onEdit={id => onEdit(id)}
            onDelete={id => onDelete(id)}
            type={type}
            isDisabled={isDisabled}
            key={index}
          />
        );
      })}
    </List>
  );
};

export default TypesList;
