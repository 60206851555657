import { AxiosError } from 'axios';
import { TeamService } from 'src/api';
import * as actions from '../actionTypes';

export const addTeamMember = data => async dispatch => {
  try {
    const response = await TeamService.addTeamMember(data);
    dispatch({
      type: actions.ADD_TEAM_MEMBER,
      payload: {
        message: {
          action: actions.ADD_TEAM_MEMBER,
          success: response.data.successMessage,
        },
        member: response.data.teamMemberContent,
      },
    });
  } catch (error) {
    dispatchError(actions.ADD_TEAM_MEMBER, error, dispatch);
  }
};

export const getAllTeamMembers = () => async dispatch => {
  try {
    const response = await TeamService.getAllTeamMembers();
    dispatch({
      type: actions.GET_ALL_TEAM_MEMBERS,
      payload: {
        members: response.data.allTeamMembers,
      },
    });
  } catch (error) {
    dispatchError(actions.GET_ALL_TEAM_MEMBERS, error, dispatch);
  }
};

export const getSingleTeamMember = id => async dispatch => {
  try {
    const response = await TeamService.getSingleTeamMember(id);
    dispatch({
      type: actions.GET_SINGLE_TEAM_MEMBER,
      payload: {
        member: response.data.fetchedTeamMember,
      },
    });
  } catch (error) {
    dispatchError(actions.GET_SINGLE_TEAM_MEMBER, error, dispatch);
  }
};

export const updateTeamMember = (id, data) => async dispatch => {
  try {
    const response = await TeamService.updateTeamMember(id, data);
    dispatch({
      type: actions.UPDATE_TEAM_MEMBER,
      payload: {
        message: {
          action: actions.UPDATE_TEAM_MEMBER,
          success: response.data.successMessage,
        },
        member: response.data.teamMemberContent,
      },
    });
  } catch (error) {
    dispatchError(actions.UPDATE_TEAM_MEMBER, error, dispatch);
  }
};

export const deleteTeamMember = id => async dispatch => {
  try {
    const response = await TeamService.deleteTeamMember(id);
    dispatch({
      type: actions.DELETE_TEAM_MEMBER,
      payload: {
        message: {
          action: actions.DELETE_TEAM_MEMBER,
          success: response.data.successMessage,
        },
        id,
      },
    });
  } catch (error) {
    dispatchError(actions.DELETE_TEAM_MEMBER, error, dispatch);
  }
};

function dispatchError(actionType, error, dispatch) {
  if (error instanceof AxiosError) {
    dispatch({
      type: actions.TEAM_ERROR,
      payload: {
        error: {
          action: actionType,
          message:
            error.response.data?.message ||
            error.response.data?.TeamError ||
            error.message ||
            'Unknown error has occured',
          at: new Date(),
        },
      },
    });
    return;
  }
  dispatch({
    type: actions.TEAM_ERROR,
    payload: {
      error: {
        action: actionType,
        message: error.message,
        at: new Date(),
      },
    },
  });
}