import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import {
  Alert,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
  Box,
} from '@mui/material';
import {
  ResourceCard,
  ResourcePagination,
  ResourcesSidebar as CreateResourceSidebar,
} from '../sections/resources';

import { connect } from 'react-redux';
import {
  addResource,
  deleteResource,
  editResource,
  getAllResources,
} from 'src/redux/actions/resource';
import {
  ADD_RESOURCE,
  DELETE_RESOURCE,
  EDIT_RESOURCE,
} from 'src/redux/actionTypes';
import DataWidget from 'src/components/widgets/DataWidget';
import CategoriesSidebar from 'src/sections/resources/CategoriesSidebar';

const ResourcesPage = ({
  resources,
  error,
  getAvailableResources,
  paginationDetails,
  deleteResource,
  addResource,
  message,
  editResource,
  loading,
}) => {
  useEffect(() => {
    if (resources.length === 0) {
      getAvailableResources({});
    }
  }, []);

  const [openSidebar, setOpenSidebar] = useState({
    show: false,
    data: null,
  });

  const handleOpenSidebar = data => {
    setOpenSidebar({ show: true, data });
  };

  const handleCloseSidebar = () => {
    setOpenSidebar({ show: false, data: null });
  };

  const [openCategorySidebar, setOpenCategorySidebar] =
    useState(false);

  const handleOpenCategorySidebar = () => {
    setOpenCategorySidebar(true);
  };

  const handleCloseCategorySidebar = () => {
    setOpenCategorySidebar(false);
  };

  const [deleting, setDeleting] = useState();
  const [deleteError, setDeleteError] = useState();
  const [deleteSuccess, setDeleteSuccess] = useState();

  useEffect(() => {
    if (error?.action === DELETE_RESOURCE) {
      setDeleteError(error.message);
    }
    if (message?.action === DELETE_RESOURCE) {
      setDeleteSuccess(message.success);
    }
    if (
      message?.action === EDIT_RESOURCE ||
      message?.action === ADD_RESOURCE
    ) {
      setDeleteSuccess(message.success);
      handleCloseSidebar();
    }
    setDeleting();
  }, [message, error]);

  return (
    <>
      <Helmet>
        <title> Dashboard: Resources | TGHrC </title>
      </Helmet>

      <Container>
        {deleteError && (
          <Alert variant="standard" severity="error" sx={{ mt: 2 }}>
            {deleteError}
          </Alert>
        )}
        {deleteSuccess && (
          <Alert variant="standard" severity="success" sx={{ mt: 2 }}>
            {deleteSuccess}
          </Alert>
        )}
        {deleting && (
          <Alert
            variant="standard"
            severity="info"
            icon={<CircularProgress size={20} />}
            sx={{ mt: 2 }}
          >
            Deleting <strong>"{deleting.title}"</strong> resource ...
          </Alert>
        )}
        <Stack
          direction="row"
          spacing={1}
          flexShrink={0}
          sx={{ my: 1, mb: 5 }}
          justifyContent="space-between"
        >
          <Typography variant="h4">Resources</Typography>

          <Box sx={{ flexGrow: 1 }}></Box>

          <CategoriesSidebar
            openSidebar={openCategorySidebar}
            onOpenSidebar={handleOpenCategorySidebar}
            onCloseSidebar={handleCloseCategorySidebar}
          />
          <CreateResourceSidebar
            openSidebar={openSidebar.show}
            onOpenSidebar={() => handleOpenSidebar(null)}
            data={openSidebar.data}
            onCloseSidebar={() => handleCloseSidebar()}
            errorApi={error}
            message={message}
            onEditSubmitted={edited => {
              editResource(openSidebar.data._id, edited);
            }}
            onSubmit={data => {
              addResource(data);
            }}
          />
        </Stack>
        <DataWidget
          title={'Resources'}
          isLoading={loading && !resources.length && !error}
          isError={
            !loading && error && !resources.length ? error : null
          }
          isEmpty={!error && !loading && !resources.length}
        >
          <Grid container spacing={3}>
            {resources.map(resource => (
              <Grid key={resource._id} item xs={12} sm={6} md={4}>
                <ResourceCard
                  resource={resource}
                  onEdit={() => {
                    handleOpenSidebar(resource);
                  }}
                  isDeleting={
                    deleting?.id === resource._id ||
                    openSidebar.data?._id === resource._id
                  }
                  onDelete={() => {
                    setDeleting({
                      id: resource._id,
                      title: resource.title,
                    });
                    setDeleteSuccess();
                    setDeleteError();
                    return deleteResource(resource._id);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </DataWidget>

        {resources.length > 0 &&
          paginationDetails &&
          paginationDetails.totalPages > 1 && (
            <ResourcePagination
              details={paginationDetails}
              onPrev={page => {
                getAvailableResources({ page });
              }}
              onNext={page => {
                getAvailableResources({ page });
              }}
            />
          )}
      </Container>
    </>
  );
};

const mapStateToProps = state => ({
  resources: state.resource.resources,
  message: state.resource.message,
  paginationDetails: state.resource.paginationDetails,
  error: state.resource.error,
  loading: state.resource.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    getAvailableResources: queries =>
      dispatch(getAllResources(queries)),
    addResource: data => dispatch(addResource(data)),
    deleteResource: id => dispatch(deleteResource(id)),
    editResource: (slug, data) => dispatch(editResource(slug, data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResourcesPage);
