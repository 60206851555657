import React from 'react';
// @mui
import { List } from '@mui/material';
import EmploymentStatusTile from './EmploymentStatusTile';

const EmploymentStatusesList = ({
  employmentStatuses,
  onEdit,
  onDelete,
  disabled,
}) => {
  return (
    <List>
      {employmentStatuses.map((item, index) => {
        const employmentStatus = {
          id: item._id,
          status: item.status,
        };
        const isDisabled = disabled === employmentStatus.id;

        return (
          <EmploymentStatusTile
            onEdit={id => onEdit(id)}
            onDelete={id => onDelete(id)}
            employmentStatus={employmentStatus}
            isDisabled={isDisabled}
            key={index}
          />
        );
      })}
    </List>
  );
};

export default EmploymentStatusesList;