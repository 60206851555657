import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Typography,
  Stack,
  Button,
  Avatar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import ModalDialog from 'src/components/Global/ModalDialog';

const StyledTeamImg = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

TeamCard.propTypes = {
  member: PropTypes.object,
  isDeleting: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

export default function TeamCard({ member, isDeleting, onDelete, onEdit }) {
  const { name, role, image } = member;

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Card sx={{ opacity: isDeleting ? 0.3 : 1 }}>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <StyledTeamImg alt={name} src={image} />
      </Box>

      <Stack spacing={1} sx={{ p: 2 }}>
        <Typography variant="subtitle1">{name}</Typography>
        <Typography variant="body2" color="text.secondary">
          {role}
        </Typography>
      </Stack>
      <Box sx={{ pl: 2, mb: 3 }}>
        <Stack direction="row">
          <Button
            size="small"
            color="secondary"
            variant="outlined"
            onClick={isDeleting ? null : onEdit}
          >
            Edit
          </Button>
          <Button
            size="small"
            color="error"
            variant="outlined"
            sx={{ ml: 2 }}
            onClick={isDeleting ? null : handleOpenModal}
          >
            Delete
          </Button>
        </Stack>
      </Box>
      <ModalDialog
        title="Delete Team Member?"
        subTitle={`Are you sure do you want to delete this team member?`}
        item={name}
        open={openModal}
        handleClose={handleCloseModal}
        handleClickOk={() => {
          handleCloseModal();
          onDelete();
        }}
      />
    </Card>
  );
}