import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
// @mui
import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
  Alert,
  CircularProgress,
} from '@mui/material';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';

import { useFetcher } from 'src/api/fetcher';
import { TrainingService } from 'src/api';
import CandidatesList from './CandidatesList';

// ----------------------------------------------------------------------

CandidatesSidebar.propTypes = {
  openSidebar: PropTypes.bool,
  onOpenSidebar: PropTypes.func,
  onCloseSidebar: PropTypes.func,
};

export default function CandidatesSidebar({
  openSidebar,
  onOpenSidebar,
  onCloseSidebar,
  candidates,
}) {
  //----------------------------------------------------------------

  return (
    <>
      <Drawer
        anchor="right"
        open={openSidebar}
        onClose={onCloseSidebar}
        PaperProps={{
          sx: { width: 320, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Training Candidates
          </Typography>
          <IconButton onClick={onCloseSidebar}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <Stack spacing={3} sx={{ px: 3 }}>
            <CandidatesList
              candidates={candidates}
              onView={categoryId => {
                // handleDelete(categoryId);
              }}
            />
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
