import {
    Alert,
    Container,
    Divider,
    List,
    Stack,
    Typography,
  } from '@mui/material';
  import { useEffect, useState } from 'react';
  import { Helmet } from 'react-helmet-async';
  import { connect } from 'react-redux';
  import DataWidget from 'src/components/widgets/DataWidget';
  import {
    addFaq,
    deleteFaq,
    editFaq,
    getAllFaqs,
  } from 'src/redux/actions/faq';
  import {
    ADD_FAQ,
    DELETE_FAQ,
    EDIT_FAQ,
  } from 'src/redux/actionTypes';
  import CreateFaqSidebar from 'src/sections/faq/CreateFaqSidebar';
  import FaqTile from 'src/sections/faq/FaqTile';
  
  const FaqPage = ({
    faqs,
    getFaqs,
    error,
    loading,
    deleteFaq,
    addFaq,
    message,
    editFaq,
  }) => {
    useEffect(() => {
      getFaqs();
    }, []);
  
    const [openSidebar, setOpenSidebar] = useState(false);
    const [editData, setEditData] = useState(null);
  
    const handleOpenSidebar = () => {
      setOpenSidebar(true);
    };
  
    const handleCloseSidebar = () => {
      setEditData(null);
      setOpenSidebar(false);
    };
  
    //Success message
    const [success, setSuccess] = useState('');
  
    useEffect(() => {
      if (
        message?.action === EDIT_FAQ ||
        message?.action === ADD_FAQ ||
        message?.action === DELETE_FAQ
      ) {
        setSuccess(
          message.success + ` ${editData?.title || ''}`,
        );
        handleCloseSidebar();
      }
    }, [message]);
  
    return (
      <>
        <Helmet>
          <title> Faqs | TGHrC </title>
        </Helmet>
  
        <Container>
          {success && (
            <Alert variant="standard" severity="success" sx={{ mt: 2 }}>
              {success}
            </Alert>
          )}
          <Stack
            direction="row"
            spacing={1}
            flexShrink={0}
            sx={{ my: 1, mb: 5 }}
            justifyContent="space-between"
          >
            <Typography variant="h4">FAQs</Typography>
            <CreateFaqSidebar
              openSidebar={openSidebar}
              onOpenSidebar={handleOpenSidebar}
              onCloseSidebar={handleCloseSidebar}
              onSubmit={({ create, data }) => {
                if (create) {
                  addFaq(data);
                } else {
                  editFaq(editData._id, data);
                }
              }}
              data={editData}
              errorApi={error}
              message={message}
            />
          </Stack>
  
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <DataWidget
              title={'faqs'}
              isLoading={loading && !faqs.length && !error}
              isError={
                !loading && error && !faqs.length ? error : null
              }
              isEmpty={!error && !loading && !faqs.length}
            >
              {faqs.map((faq, index) => {
                return (
                  <div key={index}>
                    <FaqTile
                      faq={faq}
                      index={index}
                      onEdit={() => {
                        setEditData(faq);
                        setOpenSidebar(true);
                      }}
                      onDelete={() => deleteFaq(faq._id)}
                    />
                    {index !== faqs.length - 1 && (
                      <Divider variant="inset" component="li" />
                    )}
                  </div>
                );
              })}
            </DataWidget>
          </List>
        </Container>
      </>
    );
  };
  
  const mapStateToProps = state => ({
    faqs: state.faq.faqs,
    message: state.faq.message,
    error: state.faq.error,
    loading: state.faq.loading,
  });
  
  const mapDispatchToProps = dispatch => {
    return {
      getFaqs: () => dispatch(getAllFaqs()),
      addFaq: data => dispatch(addFaq(data)),
      deleteFaq: id => dispatch(deleteFaq(id)),
      editFaq: (id, body) => dispatch(editFaq(id, body)),
    };
  };
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FaqPage);
  