import API from './_api_';

//Create a new user
const addUser = (data) => {
  return API.post(`/auth`, data);
};

//Edit user
const editUser = (id, data) => {
  return API.put(`/auth/${id}`, data);
};

//Getting all registered users
const getUsers = () => {
  return API.get(`/auth`);
};

//Delete registered user
const deleteUser = id => API.delete('auth/deleteUser?userId=' + id);

//Getting all registered users
const getCommunityUsers = () => {
  return API.get(`/auth/talent-community`);
};

//Delete registered user
const deleteCommunityUser = id =>
  API.delete('auth/deleteCommunityUser?userId=' + id);

//Change user role
const changeUserRole = (id, body) =>
  API.put('/auth/assignUserRole/' + id, body);

export default {
  addUser,
  editUser,
  getUsers,
  deleteUser,
  getCommunityUsers,
  deleteCommunityUser,
  changeUserRole,
};
