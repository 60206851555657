import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import {
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
  Button,
  TextField,
  Alert,
} from '@mui/material';
import DataWidget from 'src/components/widgets/DataWidget';
import { useFetcher } from 'src/api/fetcher';
import API from 'src/api/_api_';
import toast from 'react-hot-toast';
import RichTextEditor from 'src/components/blog/RichTextEditor';

const initState = { loading: false, error: null };

const initialFormData = {
  description: '',
  mission: '',
  vision: '',
  values: '',
  phoneNumber: '',
  emailAddress: '',
  officeAddress: '',
};

const AboutPage = () => {
  const { data, isLoading, isSuccess, isError, refetch } = useFetcher(
    '/about/getAboutContent',
  );
  const [aboutContent, setAboutContent] = useState(null);
  const [state, setState] = useState(initState);
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (isSuccess) {
      setAboutContent(data?.aboutContent[0]);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (aboutContent) {
      setFormData({
        description: aboutContent?.description,
        mission: aboutContent?.mission,
        vision: aboutContent?.vision,
        values: aboutContent?.values,
        phoneNumber: aboutContent?.phoneNumber,
        emailAddress: aboutContent?.emailAddress,
        officeAddress: aboutContent?.officeAddress,
      });
    }
  }, [aboutContent]);

  const handleChange = e => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setState(prev => ({ ...prev, loading: true }));
    try {
      if (aboutContent) {
        await toast.promise(
          API.patch(
            `/about/updateAbout?id=${aboutContent?._id}`,
            formData,
          ),
          {
            loading: `Updating content, please wait...`,
            success: `Content updated successfully!`,
            error: `There was an error while updating this content!`,
          },
          { position: 'top-center' },
        );
        refetch();
      } else {
        await toast.promise(
          API.post(`/about/addAbout`, formData),
          {
            loading: `Adding content, please wait...`,
            success: `Content added successfully!`,
            error: `There was an error while adding about content!`,
          },
          { position: 'top-center' },
        );
        refetch();
      }
    } catch (e) {
      setState(prev => ({
        ...prev,
        error:
          e?.response?.data?.message ||
          e?.message ||
          'Something went wrong, please try again.',
      }));
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: About | TGHrC </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          spacing={1}
          flexShrink={0}
          sx={{ my: 1, mb: 5 }}
          justifyContent="space-between"
        >
          <Typography variant="h4">About Content</Typography>
        </Stack>
        <DataWidget
          title={'About Content'}
          isLoading={isLoading}
          isError={isError}
        >
          <Stack spacing={3}>
            <Grid container spacing={3} justifyContent="end">
              <Grid item xs={12}>
                <TextField
                  name="description"
                  label="Company Background"
                  value={formData.description}
                  onChange={handleChange}
                  fullWidth
                  required
                  multiline
                  rows={12}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="mission"
                  label="Company Mission"
                  value={formData.mission}
                  onChange={handleChange}
                  fullWidth
                  required
                  multiline
                  rows={12}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="vision"
                  label="Company Vision"
                  value={formData.vision}
                  onChange={handleChange}
                  fullWidth
                  required
                  multiline
                  rows={12}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Company Values
                </Typography>
                <RichTextEditor
                  name="values"
                  onChange={html => {
                    setFormData(prev => ({
                      ...prev,
                      values: html,
                    }));
                  }}
                  placeholder="Company Values..."
                  html={formData.values}
                />
              </Grid>
              {state.error && (
                <Grid item xs={12}>
                  <Alert
                    severity="error"
                    sx={{ textAlign: 'center' }}
                  >
                    {state.error}
                  </Alert>
                </Grid>
              )}
            </Grid>
          </Stack>
        </DataWidget>
      </Container>
      <Container>
        <Stack
          direction="row"
          spacing={1}
          flexShrink={0}
          sx={{ my: 1, my: 5 }}
          justifyContent="space-between"
        >
          <Typography variant="h4">Contact Info</Typography>
        </Stack>
        <DataWidget
          title={'About Content'}
          isLoading={isLoading}
          isError={isError}
        >
          <Stack spacing={3}>
            <Grid container spacing={3} justifyContent="end">
              <Grid item xs={12} md={4}>
                <TextField
                  name="phoneNumber"
                  label="Phone Number"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  name="emailAddress"
                  label="Email Address"
                  value={formData.emailAddress}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  name="officeAddress"
                  label="Office Address"
                  value={formData.officeAddress}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              {state.error && (
                <Grid item xs={12}>
                  <Alert
                    severity="error"
                    sx={{ textAlign: 'center' }}
                  >
                    {state.error}
                  </Alert>
                </Grid>
              )}

              <Grid item xs={4} mt={3}>
                {!state.loading ? (
                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    color="primary"
                    onClick={handleSubmit}
                    variant="contained"
                  >
                    Update Content
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    size="large"
                    color="primary"
                    variant="contained"
                  >
                    <CircularProgress color="inherit" size={20} />
                    &nbsp; Updating Content...
                  </Button>
                )}
              </Grid>
            </Grid>
          </Stack>
        </DataWidget>
      </Container>
    </>
  );
};

export default AboutPage;
