import PropTypes from 'prop-types';
import React from 'react';

import { styled } from '@mui/material/styles';
import {
  Link,
  Card,
  Grid,
  Avatar,
  Typography,
  CardContent,
  Box,
  Stack,
  IconButton,
  MenuItem,
  Popover,
  Menu,
} from '@mui/material';
import Iconify from 'src/components/iconify/Iconify';
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalDialog from 'src/components/Global/ModalDialog';
import toast from 'react-hot-toast';

const StyledTitle = styled(Link)({
  width: '100%',
  overflow: 'hidden',
  paddingBottom: 5,
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});

const StyledInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  marginTop: theme.spacing(2),
  color: theme.palette.text.disabled,
}));

JobPostCard.propTypes = {
  job: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function JobPostCard({ job, onDelete, onPublish }) {
  const nav = useNavigate();
  const loggedInUser = JSON.parse(
    localStorage.getItem('loggedInUser'),
  );

  const copyToClipboard = useCallback(url => {
    navigator.clipboard.writeText(url).then(
      function () {
        toast.success('Job link copied to clipboard');
      },
      function (err) {
        toast.error('Could not copy text: ', err);
      },
    );
  }, []);

  const JOB_INFO = [
    {
      label:
        job?.nberOfVacantPositions == 1
          ? '1 Opening'
          : job?.nberOfVacantPositions + ' Openings',
      icon: 'material-symbols-light:chair',
    },
    { label: job?.category?.name, icon: 'eva:cube-outline' },
  ];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenMenu = event => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  const [open, setOpen] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    handleCloseMenu();
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={{ position: 'relative' }}>
          <CardContent
            sx={{
              pt: 4,
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="start"
              spacing={2}
            >
              <StyledTitle
                color="inherit"
                variant="h5"
                underline="hover"
                href={`${process.env.REACT_APP_WEB_URL}/blog/${job?.slug}`}
                target="_blank"
                component="a"
              >
                {job?.title}
              </StyledTitle>
              <IconButton onClick={handleOpenMenu}>
                <Iconify
                  icon="eva:more-vertical-fill"
                  sx={{ width: 18, height: 18 }}
                />
              </IconButton>
            </Stack>
            <Stack direction="row" alignItems="end" spacing={2}>
              <Avatar
                alt={job?.company?.companyName}
                src={job?.company?.companyLogo}
                sx={{ width: 56, height: 56 }}
              />
              <Typography
                gutterBottom
                variant="subtitle2"
                sx={{ color: 'text.disabled', display: 'block' }}
              >
                {job?.company?.companyName}
              </Typography>
            </Stack>
            <StyledInfo>
              {JOB_INFO.map((info, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ml: index === 0 ? 0 : 1.5,
                  }}
                >
                  <Iconify
                    icon={info.icon}
                    sx={{ width: 16, height: 16, mr: 0.5 }}
                  />
                  <Typography variant="p" fontSize={14}>
                    {info.label}
                  </Typography>
                </Box>
              ))}
            </StyledInfo>
          </CardContent>
        </Card>
      </Grid>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          sx={{ color: 'primary.main' }}
          href={`${process.env.REACT_APP_CAREERS_URL}/job/${job?.slug}`}
          target="_blank"
          component="a"
        >
          <Iconify icon={'eva:eye-fill'} sx={{ mr: 2 }} />
          Preview
        </MenuItem>
        <MenuItem
          sx={{ color: 'info.main' }}
          onClick={e => {
            e.preventDefault();
            copyToClipboard(
              `${process.env.REACT_APP_CAREERS_URL}/job/${job?.slug}`,
            );
          }}
          href={`${process.env.REACT_APP_CAREERS_URL}/job/${job?.slug}`}
          target="_blank"
          component="a"
        >
          <Iconify icon={'solar:copy-line-duotone'} sx={{ mr: 2 }} />
          Copy link
        </MenuItem>
        <MenuItem
          sx={{ color: 'success.main' }}
          onClick={() => {
            handleCloseMenu();
            onPublish();
          }}
        >
          <Iconify icon={'eva:external-link-fill'} sx={{ mr: 2 }} />
          {job?.isPublic === true ? 'Unpublish' : 'Publish'}
        </MenuItem>
        <>
          <MenuItem
            aria-controls={openMenu ? 'basic-menu' : undefined}
            aria-haspopup="true"
            sx={{ color: 'info.main' }}
            aria-expanded={openMenu ? 'true' : undefined}
            onClick={handleClick}
          >
            <Iconify icon={'ion:people-sharp'} sx={{ mr: 2 }} />
            Candidates
          </MenuItem>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem
              href={`${process.env.REACT_APP_CAREERS_URL}/application/${job?.slug}`}
              target="_blank"
              component="a"
            >
              <Iconify
                icon={'eva:external-link-fill'}
                sx={{ mr: 2 }}
              />{' '}
              Default View
            </MenuItem>
            <MenuItem
              href={`${process.env.REACT_APP_CAREERS_URL}/application/${job?.slug}?admin=${loggedInUser?.result?._id}`}
              target="_blank"
              component="a"
            >
              <Iconify
                icon={'eos-icons:admin-outlined'}
                sx={{ mr: 2 }}
              />{' '}
              Open as Administrator
            </MenuItem>
          </Menu>
        </>
        <MenuItem
          onClick={() => nav(`/dashboard/jobs/edit/${job?.slug}`)}
        >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={handleOpenModal}
        >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      <ModalDialog
        title="Delete Job?"
        subTitle={`Are you sure do you want to delete this job?`}
        item={job?.title}
        open={openModal}
        handleClose={handleCloseModal}
        handleClickOk={() => {
          handleCloseModal();
          onDelete();
        }}
      />
    </>
  );
}
