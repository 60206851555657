import {
  ADD_TEAM_MEMBER,
  GET_ALL_TEAM_MEMBERS,
  GET_SINGLE_TEAM_MEMBER,
  UPDATE_TEAM_MEMBER,
  DELETE_TEAM_MEMBER,
  TEAM_ERROR,
} from '../actionTypes';

const initTeamState = {
  error: null,
  members: [],
  message: null,
  loading: true,
};

const teamReducer = (state = initTeamState, action) => {
  switch (action.type) {
    case GET_ALL_TEAM_MEMBERS:
      return {
        ...state,
        error: null,
        loading: false,
        members: [...action.payload.members],
      };
    case ADD_TEAM_MEMBER:
      return {
        ...state,
        error: null,
        message: action.payload.message,
        members: [action.payload.member, ...state.members],
      };
    case GET_SINGLE_TEAM_MEMBER:
      return {
        ...state,
        error: null,
        message: action.payload.message,
        members: state.members.map(member =>
          member._id !== action.payload.member._id
            ? member
            : action.payload.member,
        ),
      };
    case UPDATE_TEAM_MEMBER:
      return {
        ...state,
        error: null,
        message: action.payload.message,
        members: state.members.map(member =>
          member._id !== action.payload.member._id
            ? member
            : action.payload.member,
        ),
      };
    case DELETE_TEAM_MEMBER:
      return {
        ...state,
        error: null,
        message: action.payload.message,
        members: state.members.filter(
          member => member._id !== action.payload.id,
        ),
      };
    case TEAM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default teamReducer;