import { Helmet } from 'react-helmet-async';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import { getUsers } from 'src/redux/actions/user';
import { getAllMessages } from 'src/redux/actions/message';
import { getAllProjects } from 'src/redux/actions/project';
import { getAllCompanies } from 'src/redux/actions/company';
import { getAllServices } from 'src/redux/actions/service';

import { AppWidgetSummary } from '../sections/app';
import { useFetcher } from 'src/api/fetcher';

const isFeatureAllowed = (feature, user) => {
  if (
    user?.allowedFeatures
      ?.map(feature => feature.toLowerCase())
      .includes('all')
  ) {
    return true;
  } else {
    return user?.allowedFeatures
      ?.map(feature => feature.toLowerCase())
      .includes(feature);
  }
};

const DashboardAppPage = ({
  loggedInUser,
  users,
  getUsers,
  messages,
  getMessages,
  projects,
  getAvailableProjects,
  companies,
  getCompanies,
  services,
  getServices,
}) => {
  const { data: allMessages } = useFetcher(
    '/messages?all=true&limit=100',
  );
  const { data: postData } = useFetcher(`/posts/getAllPosts`);
  const { data: jobs } = useFetcher(`/job/getAllJobs?all=admin`);
  const { data: trainings } = useFetcher(
    `/training/getAllTrainings?all=admin`,
  );
  const { data: faqs } = useFetcher(`/faq/getAllFaqs`);
  const { data: galleryImages } = useFetcher(`/gallery/getAllImages`);
  const { data: teamMembers } = useFetcher(`/team/getAllTeamMembers`);
  const { data: testimonials } = useFetcher(
    `/testimonial/getAllTestimonials?all=admin`,
  );
  const { data: resources } = useFetcher(
    `/resources/getAllResources?all=admin`,
  );

  useEffect(() => {
    if (users.length === 0) getUsers();
    if (allMessages?.data?.length)
      getMessages({ messages: allMessages?.data });
    if (projects.length === 0) getAvailableProjects({});
    if (companies.length === 0) getCompanies();
    if (services.length === 0) getServices();
  }, [
    users,
    allMessages?.data?.length,
    projects,
    companies,
    services,
  ]);

  return (
    <>
      <Helmet>
        <title> Dashboard | TGHrC </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome to the Admin Portal
        </Typography>

        <Grid container spacing={3}>
          {isFeatureAllowed('careers', loggedInUser) && (
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title={
                  jobs?.data?.length === 1
                    ? 'Posted Job'
                    : 'Posted Jobs'
                }
                total={jobs?.data?.length}
                color="primary"
                icon={'oui:ml-regression-job'}
                path="/dashboard/jobs"
              />
            </Grid>
          )}
          {isFeatureAllowed('trainings', loggedInUser) && (
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title={
                  trainings?.data?.length === 1
                    ? 'Available Training'
                    : 'Available Trainings'
                }
                total={trainings?.data?.length}
                color="secondary"
                icon={'oui:training'}
                path="/dashboard/trainings"
              />
            </Grid>
          )}
          {isFeatureAllowed('talent community', loggedInUser) && (
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title={
                  users.length === 1
                    ? 'Talent Community User'
                    : 'Talent Community Users'
                }
                total={users.length}
                color="success"
                icon={'fa6-solid:people-line'}
                path="/dashboard/users"
              />
            </Grid>
          )}
          {isFeatureAllowed('projects', loggedInUser) && (
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title={projects.length === 1 ? 'Project' : 'Projects'}
                total={projects.length}
                color="warning"
                icon={'octicon:project-24'}
                path="/dashboard/projects"
              />
            </Grid>
          )}
          {isFeatureAllowed('faqs', loggedInUser) && (
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title={faqs?.allFaqs?.length === 1 ? 'Faq' : 'Faqs'}
                total={faqs?.allFaqs?.length}
                color="info"
                icon={'pajamas:question-o'}
                path="/dashboard/faq"
              />
            </Grid>
          )}
          {isFeatureAllowed('services', loggedInUser) && (
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title={services.length === 1 ? 'Service' : 'Services'}
                total={services.length}
                color="error"
                icon={'carbon:service-id'}
                path="/dashboard/services"
              />
            </Grid>
          )}
          {isFeatureAllowed('blogs', loggedInUser) && (
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title={
                  postData?.allAvailablePosts?.length === 1
                    ? 'Blog'
                    : 'Blogs'
                }
                total={postData?.allAvailablePosts?.length}
                color="primary"
                icon={'jam:blogger-square'}
                path="/dashboard/blogs"
              />
            </Grid>
          )}
          {isFeatureAllowed('gallery', loggedInUser) && (
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title={
                  galleryImages?.allImages?.length === 1
                    ? 'Gallery Image'
                    : 'Gallery Images'
                }
                total={galleryImages?.allImages?.length}
                color="secondary"
                icon={'solar:gallery-bold'}
                path="/dashboard/gallery"
              />
            </Grid>
          )}
          {isFeatureAllowed('staff members', loggedInUser) && (
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title={
                  teamMembers?.allTeamMembers?.length === 1
                    ? 'Staff Member'
                    : 'Staff Members'
                }
                total={teamMembers?.allTeamMembers?.length}
                color="success"
                icon={'ri:team-line'}
                path="/dashboard/team"
              />
            </Grid>
          )}
          {isFeatureAllowed('testimonials', loggedInUser) && (
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title={
                  testimonials?.allTestimonials?.length === 1
                    ? 'Testimonial'
                    : 'Testimonials'
                }
                total={testimonials?.allTestimonials?.length}
                color="warning"
                icon={'teenyicons:message-tick-outline'}
                path="/dashboard/testimonials"
              />
            </Grid>
          )}
          {isFeatureAllowed('resources', loggedInUser) && (
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title={
                  resources?.data?.length === 1
                    ? 'Resource'
                    : 'Resources'
                }
                total={resources?.data?.length}
                color="info"
                icon={'grommet-icons:resources'}
                path="/dashboard/resources"
              />
            </Grid>
          )}
          {isFeatureAllowed('messages', loggedInUser) && (
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title={
                  messages?.length === 1 ? 'Message' : 'Messages'
                }
                total={messages?.length}
                color="error"
                icon={'typcn:messages'}
                path="/dashboard/messages"
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
};

const mapStateToProps = state => ({
  users: state.user.users,
  messages: state.message.messages,
  projects: state.project.projects,
  companies: state.company.companies,
  services: state.service.services,
  loggedInUser: state.auth.isAuthenticated.currentUser,
});

const mapDispatchToProps = dispatch => {
  return {
    getUsers: () => dispatch(getUsers()),
    getMessages: data => dispatch(getAllMessages(data)),
    getAvailableProjects: queries =>
      dispatch(getAllProjects(queries)),
    getCompanies: () => dispatch(getAllCompanies()),
    getServices: () => dispatch(getAllServices()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardAppPage);
