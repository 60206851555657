import API from "./_api_";

//Create a new FAQ
const createFaq = (data) => API.post("/faq/addFaq", data);

//Getting all faqs
const getFaqs = () => {
  return API.get(`/faq/getAllFaqs`);
};

//EDIT FAQ
const editFaq = (id, body) =>
  API.put("/faq/updateFaq?faqId=" + id, body);

//DELETE FAQ
const deleteFaq = (id) =>
  API.delete("/faq/deleteFaq?faqId=" + id);

export default { createFaq, getFaqs, deleteFaq, editFaq };
