import {
    ADD_FAQ,
    DELETE_FAQ,
    EDIT_FAQ,
    GET_FAQS,
    FAQ_ERROR,
  } from '../actionTypes';
  
  const initFaqstate = {
    error: null,
    loading: true,
    faqs: [],
  };
  
  const faqReducer = (state = initFaqstate, action) => {
    switch (action.type) {
      case GET_FAQS:
        return {
          error: null,
          loading: false,
          faqs: [...action.payload.faqs],
        };
      case ADD_FAQ:
        return {
          ...state,
          error: null,
          message: action.payload.message,
          faqs: [action.payload.faq, ...state.faqs],
        };
      case EDIT_FAQ:
        return {
          ...state,
          error: null,
          message: action.payload.message,
          faqs: state.faqs.map(faq =>
            faq._id !== action.payload.faq._id
              ? faq
              : action.payload.faq,
          ),
        };
      case DELETE_FAQ:
        return {
          ...state,
          error: null,
          message: action.payload.message,
          faqs: state.faqs.filter(
            faq => faq._id !== action.payload.id,
          ),
        };
      case FAQ_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      default:
        return state;
    }
  };
  
  export default faqReducer;
  