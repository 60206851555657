import API from './_api_';

// Training endpoints
const createTraining = data => API.post('/training/createTraining', data);
const getAllTrainings = () => API.get('/training/getAllTrainings');
const getSingleTraining = id => API.get('/training/getSingleTraining?slug=' + id);
const updateTraining = (id, data) => API.put('/training/updateTraining?slug=' + id, data);
const updateTrainingCandidates = (id, data) => API.put('/training/updateTrainingCandidates?slug=' + id, data);
const publishTraining = id => API.post('/training/publishTraining/' + id);
const deleteTraining = id => API.delete('/training/deleteTraining/' + id);

// Category endpoints
const addCategory = data => API.post('/training/addCategory', data);
const getAllCategories = () => API.get('/training/getAllCategories');
const updateCategory = (id, name) => API.put('/training/updateCategory/' + id, { name });
const deleteCategory = id => API.delete('/training/deleteCategory/' + id);

// Candidate endpoints
const createCandidate = data => API.post('/training/createCandidate', data);
const getAllCandidates = () => API.get('/training/getAllCandidates');
const getSingleCandidate = id => API.get('/training/getSingleCandidate?id=' + id);
const updateCandidate = (id, data) => API.put('/training/updateCandidate?id=' + id, data);
const deleteCandidate = id => API.delete('/training/deleteCandidate/' + id);

export default {
  createTraining,
  getAllTrainings,
  getSingleTraining,
  updateTraining,
  updateTrainingCandidates,
  publishTraining,
  deleteTraining,
  addCategory,
  getAllCategories,
  updateCategory,
  deleteCategory,
  createCandidate,
  getAllCandidates,
  getSingleCandidate,
  updateCandidate,
  deleteCandidate,
};