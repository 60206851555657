import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import BlogPage from './pages/blog/BlogsPage';
import UserPage from './pages/UserPage';
import Page404 from './pages/Page404';
import ProjectsPage from './pages/ProjectsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import CreateBlog from './pages/blog/CreateBlog';
import SkillsPage from './pages/CompaniesPage';
import ServicePage from './pages/ServicePage';
import EditBlog from './pages/blog/EditBlog';
import MessagesPage from './pages/MessagesPage';
import AboutPage from './pages/AboutPage';
import TestimonialPage from './pages/TestimonialPage';
import FaqPage from './pages/FaqPage';
import JobsPage from './pages/job/JobsPage';
import CreateJob from './pages/job/CreateJob';
import EditJob from './pages/job/EditJob';
import GalleryPage from './pages/GalleryPage';
import ResourcesPage from './pages/ResourcesPage';
import TeamPage from './pages/TeamPage';
import TrainingsPage from './pages/training/TrainingsPage';
import CreateTraining from './pages/training/CreateTraining';
import EditTraining from './pages/training/EditTraining';
import TalentCommunityUsersPage from './pages/TalentCommunityUsersPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'users', element: <UserPage /> },
        { path: 'talent-community', element: <TalentCommunityUsersPage /> },
        { path: 'projects', element: <ProjectsPage /> },
        { path: 'resources', element: <ResourcesPage /> },
        { path: 'blogs', element: <BlogPage /> },
        { path: 'gallery', element: <GalleryPage /> },
        { path: 'blogs/create', element: <CreateBlog /> },
        { path: 'blogs/edit/:slug', element: <EditBlog /> },
        { path: 'jobs', element: <JobsPage /> },
        { path: 'jobs/companies', element: <SkillsPage /> },
        { path: 'jobs/create', element: <CreateJob /> },
        { path: 'jobs/edit/:slug', element: <EditJob /> },
        { path: 'trainings', element: <TrainingsPage /> },
        { path: 'trainings/create', element: <CreateTraining /> },
        { path: 'trainings/edit/:slug', element: <EditTraining /> },
        { path: 'faq', element: <FaqPage /> },
        { path: 'services', element: <ServicePage /> },
        { path: 'testimonials', element: <TestimonialPage /> },
        { path: 'messages', element: <MessagesPage /> },
        { path: 'about', element: <AboutPage /> },
        { path: 'team', element: <TeamPage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
