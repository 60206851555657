import API from './_api_';

// Add Image
const addImage = image => API.post('/gallery/addImage', { image });

// Get All Images
const getAllImages = () => API.get('/gallery/getAllImages');

// Get All Images
const updateImageTitle = (id, title) => API.put('/gallery/updateImageTitle', {
  imageId: id,
  newTitle: title,
});

// Delete Image
const deleteImage = id => API.delete('/gallery/deleteImage?imageId=' + id);

export default {
  addImage,
  getAllImages,
  updateImageTitle,
  deleteImage,
};