import { Helmet } from 'react-helmet-async';

import {
  Grid,
  Button,
  Container,
  Stack,
  Typography,
  Alert,
  CircularProgress,
} from '@mui/material';
import Iconify from '../components/iconify';
import { useFetcher } from 'src/api/fetcher';
import { useMemo, useState } from 'react';
import DataWidget from 'src/components/widgets/DataWidget';
import { GalleryService } from 'src/api';
import ImageCard from 'src/sections/gallery/ImageCard';
import ChooseFileImage from 'src/components/Global/files/ChooseFileImage';
import toast from 'react-hot-toast';

const initialState = { addLoading: false, removeLoading: false };

export default function GalleryPage() {
  const {
    data: galleryData,
    isError,
    isLoading,
    refetch,
  } = useFetcher(`/gallery/getAllImages`);

  const [galleryImages, setGalleryImages] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState('');
  const [state, setState] = useState(initialState);

  useMemo(
    () => setGalleryImages(galleryData?.allImages || []),
    [galleryData?.allImages],
  );

  return (
    <>
      <Helmet>
        <title> Gallery | TGHrC</title>
      </Helmet>

      <Container>
        {error && (
          <Alert variant="standard" severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert variant="standard" severity="success" sx={{ mt: 2 }}>
            {success}
          </Alert>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Gallery
          </Typography>

          <Button
            variant="contained"
            startIcon={state.addLoading ? <CircularProgress color='inherit' size={20}/> : <Iconify icon="eva:plus-fill" />}
            sx={{ py: 0 }}
          >
            <ChooseFileImage
              title="Add New Image"
              onSelect={async image => {
                setState({ ...state, addLoading: true });
                await GalleryService.addImage(image);
                refetch();
                toast.success('Image added successfully');
                setState({ ...state, addLoading: false });
              }}
              selected={null}
              error={error}
              noTitle={true}
              isLoading={state.addLoading}
            />
          </Button>
        </Stack>

        <DataWidget
          title={'Images'}
          isLoading={isLoading}
          isError={isError}
          isEmpty={!galleryData?.allImages?.length && !isLoading && !isError}
        >
          <Grid container spacing={3}>
            {galleryImages.map((item, index) => {
              return (
                <ImageCard
                  key={item._id}
                  post={item}
                  refetch={refetch}
                  index={index}
                  onDelete={async () => {
                    setState({ ...state, removeLoading: true });
                    await GalleryService.deleteImage(item._id);
                    refetch();
                    toast.success('Image deleted successfully');
                    setState({ ...state, removeLoading: true });
                  }}
                  onPublish={async () => {
                    setSuccess('');
                    setError('');
                    const res = await GalleryService.publishPost(
                      item._id,
                      !item.isPublic,
                    );
                    if (res.error) {
                      setError(res.error);
                      return;
                    }
                    setSuccess(res.message);
                    setGalleryImages(prev =>
                      prev.map((postA, index) =>
                        postA.id === item._id
                          ? { ...postA, isPublic: !item.isPublic }
                          : postA,
                      ),
                    );
                  }}
                />
              );
            })}
          </Grid>
        </DataWidget>
      </Container>
    </>
  );
}
