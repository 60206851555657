import {
    GET_COMMUNITY_USERS,
    DELETE_COMMUNITY_USER,
    CHANGE_COMMUNITY_ROLE,
    USER_COMMUNITY_ERROR,
  } from '../actionTypes';
  
  const initUserState = {
    error: null,
    loading: true,
    users: [],
  };
  
  const communityReducer = (state = initUserState, action) => {
    switch (action.type) {
      case GET_COMMUNITY_USERS:
        return {
          error: null,
          loading: false,
          users: [...action.payload.users],
        };
      case DELETE_COMMUNITY_USER:
        return {
          ...state,
          error: null,
          message: action.payload.message,
          users: state.users.filter(
            user => user._id !== action.payload.id,
          ),
        };
      case CHANGE_COMMUNITY_ROLE:
        return {
          ...state,
          error: null,
          message: action.payload.message,
          users: state.users.map(user =>
            user._id !== action.payload.updatedUser._id
              ? user
              : action.payload.updatedUser,
          ),
        };
      case USER_COMMUNITY_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      default:
        return state;
    }
  };
  
  export default communityReducer;
  