import React, { useRef, useState, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import { Stack, CircularProgress } from '@mui/material';
import API from 'src/api/_api_';

const RichTextEditor = ({ placeholder, onChange, html }) => {
  const editor = useRef(null);
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);

  async function extractText(event) {
    const file = event.target.files[0];
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await API.post('/extract-text', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setText(response?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (text) {
      onChange(text);
    }
  }, [text]);

  return (
    <div>
      <JoditEditor
        ref={editor}
        value={html}
        tabIndex={1}
        onChange={onChange}
      />
      <Stack flexDirection="row" justifyContent="flex-end" mt={1}>
        {loading && (
          <div style={{ display: "flex", alignItems: "center", gap: "10px", marginRight: "10px" }}>
            <CircularProgress size="20px"/>
            <span style={{ fontSize: "13px" }}>Extracting text...</span>
          </div>
        )}
        <input
          type="file"
          accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          style={{ width: '150px' }}
          onChange={extractText}
        />
      </Stack>
    </div>
  );
};

export default RichTextEditor;
