import React, { useState } from 'react';
import { RemoveRedEye } from '@mui/icons-material';
import {
  Stack,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material';
import DetailsDialog from 'src/components/Global/DetailsDialog';

const CandidateTile = ({ onView, isDisabled, candidate }) => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const unwantedKeys = [
    'slug',
    '_id',
    'createdAt',
    'updatedAt',
    '__v',
    'id',
  ];

  return (
    <>
      <ListItem
        secondaryAction={
          <Stack direction="row">
            <IconButton
              edge="end"
              aria-label="delete"
              size="small"
              disabled={isDisabled}
              onClick={handleOpenModal}
            >
              <RemoveRedEye size="small" />
            </IconButton>
          </Stack>
        }
      >
        <ListItemText
          primary={candidate?.firstName + ' ' + candidate?.lastName}
          sx={{ color: isDisabled ? '#d3d3d3' : undefined }}
        />
        <DetailsDialog
          title="Candidate Details"
          body={
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableBody>
                  {Object.entries(candidate)
                    .filter(([key]) => !unwantedKeys.includes(key))
                    .map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row">
                          {key.charAt(0).toUpperCase() + key.slice(1)}
                        </TableCell>
                        <TableCell align="right">{value}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
          open={openModal}
          handleClose={handleCloseModal}
          handleClickOk={() => {
            handleCloseModal();
            onView(candidate.id);
          }}
        />
      </ListItem>

      <Divider />
    </>
  );
};

export default CandidateTile;
