import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import {
  Link,
  Card,
  Grid,
  Avatar,
  Typography,
  CardContent,
  Stack,
  IconButton,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';

import { fDate } from '../../utils/formatTime';

import SvgColor from '../../components/svg-color';
import Iconify from 'src/components/iconify/Iconify';
import { useState } from 'react';
import ModalDialog from 'src/components/Global/ModalDialog';
import logoImage from 'src/assets/logo/logo.png';
import { GalleryService } from 'src/api';

const StyledCardMedia = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)',
});

const StyledTitle = styled(Link)({
  width: '100%',
  textDecoration: 'none',
  color: 'inherit',
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2),
}));

const StyledInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  marginTop: theme.spacing(2),
  color: theme.palette.text.disabled,
}));

const StyledCover = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

ImageCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function ImageCard({ post, onDelete, onPublish, refetch }) {
  const { image, title, author, authorPicture, createdAt } = post;

  const [openModal, setOpenModal] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const [updateLoading, setUpdateLoading] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleTitleUpdate = async () => {
    if (newTitle && newTitle !== '') {
      setUpdateLoading(true);
      await GalleryService.updateImageTitle(post?._id, newTitle);
      refetch();
    }
    setEditTitle(false);
    setUpdateLoading(false);
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={{ position: 'relative' }}>
          <StyledCardMedia>
            <SvgColor
              color="paper"
              src="/assets/icons/shape-avatar.svg"
              sx={{
                width: 80,
                height: 36,
                zIndex: 9,
                bottom: -15,
                position: 'absolute',
                color: 'background.paper',
              }}
            />
            <StyledAvatar src={logoImage} alt="tghrc">
              S
            </StyledAvatar>

            <StyledCover alt={title} src={image} />
          </StyledCardMedia>

          <CardContent>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
            >
              {editTitle ? (
                <TextField
                  value={newTitle}
                  fullWidth
                  onChange={e => setNewTitle(e.target.value)}
                  onBlur={handleTitleUpdate}
                  autoFocus
                />
              ) : (
                <StyledTitle>{title}</StyledTitle>
              )}
              <IconButton onClick={handleOpenModal}>
                <Iconify icon="mdi:trash" sx={{ color: 'red' }} />
              </IconButton>
            </Stack>
            <Stack direction="row" justifyContent="flex-end">
              <Button
                startIcon={
                  updateLoading ? <CircularProgress size={20}/> : <></>
                }
                onClick={() => {
                  !updateLoading && setEditTitle(!editTitle);
                }}
              >
                {editTitle
                  ? 'Save'
                  : title && title !== ''
                  ? 'Edit Title'
                  : 'Add Title'}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <ModalDialog
        title="Delete Image?"
        subTitle={`Are you sure you want to delete this image?`}
        open={openModal}
        handleClose={handleCloseModal}
        handleClickOk={() => {
          handleCloseModal();
          onDelete();
        }}
      />
    </>
  );
}
