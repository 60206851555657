import {
  Container,
  Grid,
  Stack,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { ArrowBack } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import {
  createCompany,
  deleteCompany,
  updateCompany,
  getAllCompanies,
  getSingleCompany,
} from 'src/redux/actions/company';
import {
  CREATE_COMPANY,
  DELETE_COMPANY,
  UPDATE_COMPANY,
  GET_COMPANY,
} from 'src/redux/actionTypes';
import CreateCompanySidebar from 'src/sections/company/CreateCompanySidebar';
import CompanyCard from '../sections/company/CompanyCard';
import DataWidget from 'src/components/widgets/DataWidget';
import MessageAlert from 'src/components/widgets/MessageAlert';
import CompanyCategoriesSidebar from 'src/sections/job/company/CompanyCategoriesSidebar';

const CompaniesPage = ({
  companies,
  getCompanies,
  error,
  deleteCompany,
  createCompany,
  message,
  loading,
  updateCompany,
  getSingleCompany,
}) => {

  useEffect(() => {
    getCompanies();
  }, []);

  const [openSidebar, setOpenSidebar] = useState(false);
  const [editData, setEditData] = useState(null);

  const handleOpenSidebar = () => {
    setOpenSidebar(true);
  };

  const handleCloseSidebar = () => {
    setEditData(null);
    setOpenSidebar(false);
  };

  const [openCategorySidebar, setOpenCategorySidebar] = useState(false);

  const handleOpenCategorySidebar = () => {
    setOpenCategorySidebar(true);
  };

  const handleCloseJobSidebar = () => {
    setOpenCategorySidebar(false);
  };

  const [success, setSuccess] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');

  useEffect(() => {
    setLoadingMessage('');
    setErrorMessage('');
    if (
      message?.action === UPDATE_COMPANY ||
      message?.action === CREATE_COMPANY ||
      message?.action === DELETE_COMPANY
    ) {
      setSuccess(message.success + ` ${editData?.name || ''}`);
      handleCloseSidebar();
    }
    if (message?.action === GET_COMPANY) {
      setSuccess(message.success);
    }
  }, [message]);

  useEffect(() => {
    if (
      error?.action === GET_COMPANY ||
      error?.action === DELETE_COMPANY
    ) {
      setErrorMessage(error?.message);
      setLoadingMessage('');
      setSuccess('');
    }
  }, [error]);
  return (
    <>
      <Helmet>
        <title> Companies | TGHrC </title>
      </Helmet>

      <Container>
        <MessageAlert
          state={{
            error: errorMessage,
            success,
            loading: loadingMessage,
          }}
        />
        <Stack
          direction="row"
          spacing={2}
          flexShrink={0}
          sx={{ my: 1, mb: 5 }}
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center">
            <Link to="../jobs">
              <IconButton aria-label="delete" size="medium">
                <ArrowBack fontSize="small" />
              </IconButton>
            </Link>
            <Typography variant="h4">Companies</Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }}></Box>
          <CompanyCategoriesSidebar
            openSidebar={openCategorySidebar}
            onOpenSidebar={handleOpenCategorySidebar}
            onCloseSidebar={handleCloseJobSidebar}
          />
          <CreateCompanySidebar
            openSidebar={openSidebar}
            onOpenSidebar={handleOpenSidebar}
            onCloseSidebar={handleCloseSidebar}
            onSubmit={({ create, data }) => {
              if (create) {
                createCompany(data);
              } else {
                updateCompany(editData._id, data);
              }
            }}
            data={editData}
            errorApi={error}
            message={message}
          />
        </Stack>
        <DataWidget
          title={'Companies'}
          isLoading={loading && !companies.length && !error}
          isError={
            !loading && error && !companies.length ? error : null
          }
          isEmpty={!error && !loading && !companies.length}
        >
          <Grid container spacing={3}>
            {companies.map((company, index) => (
              <CompanyCard
                key={company._id}
                company={company}
                onDelete={() => {
                  setSuccess('');
                  setErrorMessage('');
                  setLoadingMessage(
                    `Deleting company ${company.name}, please wait,...`,
                  );
                  deleteCompany(company._id);
                }}
                onEdit={() => {
                  setEditData(company);
                  setOpenSidebar(true);
                }}
                onView={() => {
                  setSuccess('');
                  setErrorMessage('');
                  setLoadingMessage(
                    `Loading company ${company.name}, please wait,...`,
                  );
                  getSingleCompany(company._id);
                }}
              />
            ))}
          </Grid>
        </DataWidget>
      </Container>
    </>
  );
};

const mapStateToProps = state => ({
  companies: state.company.companies,
  message: state.company.message,
  error: state.company.error,
  loading: state.company.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    getCompanies: () => dispatch(getAllCompanies()),
    createCompany: data => dispatch(createCompany(data)),
    deleteCompany: id => dispatch(deleteCompany(id)),
    updateCompany: (id, body) =>
      dispatch(updateCompany(id, body)),
    getSingleCompany: id =>
      dispatch(getSingleCompany(id)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompaniesPage);