import {
  Button,
  Stack,
  Typography,
  Box,
  LinearProgress,
} from '@mui/material';
import { useState } from 'react';
import { uploadSingle } from 'src/utils/cloudinary';

const ChooseFileImage = ({
  title,
  onSelect,
  selected,
  error,
  noTitle = false,
  isLoading = false,
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = e => {
    setIsUploading(true);
    const files = e.target.files;
    if (files.length === 0) return;

    // setSelectedImage(files[0]);
    var reader = new FileReader();

    reader.onloadend = async function () {
      const img = await uploadSingle(reader.result);
      setIsUploading(false);
      onSelect(img);
    };

    if (files[0]) {
      reader.readAsDataURL(files[0]);
    }
  };
  return (
    <Stack sx={{ my: 1 }} alignItems="start" spacing={2}>
      {!noTitle && <Typography>{title}</Typography>}

      {selected && (
        <img
          src={selected}
          alt="Choosen"
          style={{ objectFit: 'contain', height: 200 }}
        />
      )}
      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
      <Button variant="contained" component="label">
        {selected && !isUploading
          ? 'Change Image'
          : isLoading || isUploading
          ? 'Uploading Image ...'
          : 'Upload Image'}
        <input
          type="file"
          hidden
          accept="image/*"
          onChange={handleFileChange}
        />
      </Button>
    </Stack>
  );
};

export default ChooseFileImage;
